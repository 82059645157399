import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  FaEye,
  FaFileExcel,
  FaFileExport,
  FaSearch,
  FaEdit,
  FaDev,
  FaUserEdit,
} from "react-icons/fa";
import "./ViewMember.css";
import axios from "axios";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { RiDeleteBin6Line } from "react-icons/ri";
import Modal from "react-modal";
import Webcam from "react-webcam";
import swal from "sweetalert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

const ViewMember = () => {
  const [isModalOpen1, setIsModalOpen1] = useState(false);
  const [productdata1, setProductdata1] = useState([]);
  const [fileValid2, setFileValid2] = useState(true);
  const modalRef = useRef(null);
  const navigate = useNavigate();
  const modalRef1 = useRef(null);
  const location = useLocation();
  const selected = location.state?.data;
  //  const [currentPage, setCurrentPage] = useState(1);
  //const [itemsPerPage, setItemsPerPage] = useState(1);
  const [currentDate, setCurrentDate] = useState(new Date());
  // const [result, setResult] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [FieldValid, setFileValid1] = useState("");
  const [currentdate, setCurrentdate] = useState("");
  const [dueDateColor, setDueDateColor] = useState("");
  const [dueDateColor2, setDueDateColor2] = useState("");
  const [dueDateColor3, setDueDateColor3] = useState("");
  const [stateDate1, setStateDate1] = useState("");
  //console.log("stateDate1",stateDate1)
  const [modifiedDate, setmodifiedDate] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dueDateYellow, setDueDateYellow] = useState(false);
  const [dueDateState, setDueDateState] = useState("");

  const [refresh, setRefresh] = useState(false);
  //falsee.log("object", stateDate1);
  const options = [
    {
      id: "1",
      name: "Tiruvallur",
      secname: "TIR_",
    },
    {
      id: "2",
      name: "Vellore",
      secname: "VLR_",
    },
    {
      id: "3",
      name: "Ranipet",
      secname: "RPT_",
    },
    {
      id: "4",
      name: "Kanchipuram",
      secname: "KNC_",
    },
    {
      id: "5",
      name: "Chengalpattu",
      secname: "CGL_",
    },
    {
      id: "6",
      name: "Krishnagiri",
      secname: "KRI_",
    },
    {
      id: "7",
      name: "Tirupattur",
      secname: "TPT_",
    },
    {
      id: "8",
      name: "Tiruvannamalai",
      secname: "TVM_",
    },
    {
      id: "9",
      name: "Villupuram",
      secname: "VLR_",
    },
    {
      id: "10",
      name: "Dharmapuri",
      secname: "DPI_",
    },
    {
      id: "11",
      name: "Kallakurichi",
      secname: "KKC_",
    },
    {
      id: "12",
      name: "Pudicherry",
      secname: "PY_",
    },
    {
      id: "13",
      name: "Cuddalore",
      secname: "CDL_",
    },
    {
      id: "14",
      name: "Salem",
      secname: "SLM_",
    },
    {
      id: "15",
      name: "Nilgiris",
      secname: "NIL_",
    },
    {
      id: "16",
      name: "Erode",
      secname: "ERD_",
    },
    {
      id: "17",
      name: "Namakkal",
      secname: "NML_",
    },
    {
      id: "18",
      name: "Perambalur",
      secname: "PMR_",
    },
    {
      id: "19",
      name: "Ariyalur",
      secname: "ALR_",
    },
    {
      id: "20",
      name: "Mayiladuthurai",
      secname: "MAD_",
    },
    {
      id: "21",
      name: "Kanniyakumari",
      secname: "KK_",
    },
    {
      id: "22",
      name: "Thiruchirapalli",
      secname: "TIR_",
    },
    {
      id: "23",
      name: "Karur",
      secname: "KR_",
    },
    {
      id: "24",
      name: "Coimbatore",
      secname: "CBE_",
    },
    {
      id: "25",
      name: "Thanjavur",
      secname: "TJ_",
    },
    {
      id: "26",
      name: "Tiruvarur",
      secname: "TVR_",
    },
    {
      id: "27",
      name: "Nagapattinam",
      secname: "NAG_",
    },
    {
      id: "28",
      name: "Tiruppur",
      secname: "TUP_",
    },
    {
      id: "29",
      name: "Pudukkottai",
      secname: "PKT_",
    },
    {
      id: "30",
      name: "Dindugal",
      secname: "DG_",
    },
    {
      id: "31",
      name: "Theni",
      secname: "TNI_",
    },
    {
      id: "32",
      name: "Sivagangi",
      secname: "SGI_",
    },
    {
      id: "33",
      name: "Madurai",
      secname: "MDU_",
    },
    {
      id: "34",
      name: "Virudhunagar",
      secname: "VNR_",
    },
    {
      id: "35",
      name: "Thenkasi",
      secname: "TKS_",
    },
    {
      id: "36",
      name: "Ramanathapuram",
      secname: "RMT_",
    },
    {
      id: "37",
      name: "Thoothukudi",
      secname: "TUTI_",
    },
    {
      id: "38",
      name: "Tirunalveli",
      secname: "TEN_",
    },
  ];

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const closeModal1 = () => {
    setIsModalOpen1(false);
  };

  const handleSubmit = (id) => {
    // console.log("index", id);
    if (id) {
      // console.log("Selected Member ID:", member_id);
      navigate("/SideBar/ViewMember/MemberDetails", {
        state: { data: id },
      });
    }
  };
  useEffect(() => {
    const currentDate = new Date();
    const originalDate = currentDate.getDate();
    const originalMonth = currentDate.getMonth() + 1; // Note: January is 0, so we add 1
    const originalYear = currentDate.getFullYear();

    setCurrentdate(
      `${originalDate.toString().padStart(2, "0")}-${originalMonth
        .toString()
        .padStart(2, "0")}-${originalYear}`
    );

    const modifiedDate = new Date(currentDate);
    modifiedDate.setDate(modifiedDate.getDate() + 365);

    const modifiedDateResult = modifiedDate.getDate();
    const modifiedMonthResult = modifiedDate.getMonth() + 1;
    const modifiedYearResult = modifiedDate.getFullYear();

    setmodifiedDate(
      `${modifiedDateResult.toString().padStart(2, "0")}-${modifiedMonthResult
        .toString()
        .padStart(2, "0")}-${modifiedYearResult}`
    );
  }, []);

  useEffect(() => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const yyyy = today.getFullYear();

    setCurrentDate(`${dd}-${mm}-${yyyy}`);
  }, []);

  const visiblePages = 3;
  const generatePaginationNumbers = () => {
    const paginationNumbers = [];
    let startPage = Math.max(currentPage - 1, 1);
    let endPage = Math.min(startPage + visiblePages - 1, totalPages);

    if (currentPage > Math.ceil(visiblePages / 2)) {
      paginationNumbers.push("...");
    }

    for (let i = startPage; i <= endPage; i++) {
      paginationNumbers.push(i);
    }

    if (endPage < totalPages - 1) {
      paginationNumbers.push("...");
      paginationNumbers.push(totalPages);
    }

    return paginationNumbers;
  };
  const [productData, setProductData] = useState([]);
  const [filter, setFilter] = useState([]);
  const [query, setQuery] = useState("");
  const [query1, setQuery1] = useState("");

  const handleSubmit1 = (member_id, company_id) => {
    const foundMember = productData.find(
      (member) => member.member_id === member_id
    );
    const CompanyDetails = productdata1.find(
      (company) => company.company_id === company_id
    );

    console.log("klo", CompanyDetails);
    if (foundMember) {
      setFormData1({
        ...formData1,
        company_name: foundMember.company_name,
        company_id: foundMember.company_id,
        member_id: foundMember.member_id,
        companyMember: foundMember.member_name,
        aadharNumber: foundMember.aadharnumber,
        phoneNumber: foundMember.phone_number,
        alternativeNumber: foundMember.alter_number,
        dateOfBirth: foundMember.dob,
        age: foundMember.age,
        shop: CompanyDetails.shope,
        file: foundMember.member_image,
        education: foundMember.education,
        blood: foundMember.blood_group,
        company_address: CompanyDetails.company_address,
        company_distric: CompanyDetails.company_distric,
        refference_num: CompanyDetails.refference_num,
        company_thaluk: CompanyDetails.company_thaluk,
      });
    } else {
      console.log("Member not found!");
    }
  };
  const [formData1, setFormData1] = useState({
    company_address: "",
    company_distric: "",
    refference_num: "",
    company_thaluk: "",
    company_name: "",
    company_id: "",
    member_id: "",
    companyMember: "",
    aadharNumber: "",
    phoneNumber: "",
    alternativeNumber: "",
    age: "",
    blood: "",
    dateOfBirth: "",
    education: "",
    file: null,
    shop: "",
  });
  const fetchData1 = async () => {
    try {
      const req = await fetch("https://www.synologicsserver.in//user2");
      const res = await req.json();
      setProductData(res);
      setFilter(res);
    } catch (error) {
      console.log(error);
    }
  };

  // const getData = () => {
  //   fetch("https://jsonplaceholder.typicode.com/users")
  //     .then((response) => response.json())
  //     .then((res) => setResult(res))
  //     .catch((error) => {
  //       console.error('Error fetching data:', error);
  //     });
  // };
  useEffect(() => {
    fetchData();
    fetchData1();

    // getData()
  }, []);
  useEffect(() => {
    handleDueDate();
  }, [refresh]);

  const fetchData = async () => {
    try {
      const req = await fetch("https://www.synologicsserver.in//user1");
      const res = await req.json();
      setProductdata1(res);
    } catch (error) {
      console.log(error);
    }
  };

  const handleInput = (e) => {
    const inputValue = e.target.value;
    setQuery(inputValue);
    const result = productData.filter((item) => {
      const memberNameMatch = item.member_id
        .toLowerCase()
        .includes(inputValue.toLowerCase());
      const companyNameMatch = item.company_name
        .toLowerCase()
        .includes(inputValue.toLowerCase());
      const companyMemberId = item.phone_number
        .toLowerCase()
        .includes(inputValue.toLowerCase());
      return memberNameMatch || companyNameMatch || companyMemberId;
    });
    setFilter(result);
  };

  // const handleInputChange = (e) => {
  //     const inputValue = e.target.value;
  //     setQuery1(inputValue);

  //     const result = productdata1.filter((item) =>
  //         item.company_name.toLowerCase().includes(inputValue.toLowerCase())
  //     );

  //     setFilter1(result);

  //     const memberIds = result.map((item) => item.company_id);
  //     setCompanyid(memberIds);
  // };
  const [formData2, setFormData2] = useState({
    companyMember: "",
    aadharNumber: "",
    phoneNumber: "",
    alternativeNumber: "",
    age: "",
    blood: "",
    dateOfBirth: "",
    education: "",
    file1: null,
  });

  const handleInputChange1 = (e) => {
    const { name, value } = e.target;
    setFormData2({
      ...formData2,
      [name]: value,
    });

    if (name === "aadharNumber") {
      const regex = /^\d{12}$/;
      const isValidPhoneNumber = regex.test(value);
      setFormErrors({
        ...formErrors,
        [name]: !isValidPhoneNumber,
      });
    }
    if (name === "phoneNumber") {
      // Simple phone number pattern validation (10 digits)
      const phonePattern = /^[6789]\d{9}$/;
      const isValidPhoneNumber = phonePattern.test(value);
      setFormErrors({
        ...formErrors,
        [name]: !isValidPhoneNumber,
      });
    }
    if (name === "alternativeNumber") {
      // Simple phone number pattern validation (10 digits)
      const phonePattern = /^[6789]\d{9}$/;
      const isValidPhoneNumber = phonePattern.test(value);
      setFormErrors({
        ...formErrors,
        [name]: !isValidPhoneNumber,
      });
    }
    if (name === "age") {
      const ageValue = parseInt(value, 10);
      const isValidAge = !isNaN(ageValue) && ageValue >= 10 && ageValue <= 100;
      setFormErrors({
        ...formErrors,
        [name]: !isValidAge,
      });
    }
  };
  const handleInputBlood = (e) => {
    const selectedOptionName = e.target.value;
    setFormData2({ ...formData2, blood: selectedOptionName });
  };
  const bloodGroup = [
    {
      blood: "A+",
    },
    {
      blood: "A-",
    },
    {
      blood: "B+",
    },
    {
      blood: "B-",
    },
    {
      blood: "O+",
    },
    {
      blood: "O-",
    },
    {
      blood: "AB+",
    },
    {
      blood: "AB-",
    },
  ];

  const [formErrors, setFormErrors] = useState({
    companyMember: false,
    aadharNumber: false,
    phoneNumber: false,
    alternativeNumber: false,
    age: false,
    referenceNumber: false,
  });

  const [filename1, setFilename1] = useState("");
  const [filenameimage, setFilenameimage] = useState("");
  const [showFileInput1, setShowFileInput1] = useState(true);
  const [showFileInput2, setShowFileInput2] = useState(true);
  const [img3, setImg3] = useState(null);
  // console.log("hu", img3);
  const webcamRef = useRef(null);

  const videoConstraints = {
    width: 420,
    height: 420,
    facingMode: "user",
  };

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    const timestamp = Date.now();
    const filename = `photo_${timestamp}.jpg`;
    const imageBlob = dataURItoBlob(imageSrc);
    const imageFile = new File([imageBlob], filename, { type: "image/jpeg" });
    setImg3(imageFile);
    setFilename1(imageFile);
    setFilenameimage(imageFile.name);
    setShowFileInput1(true);
    setShowFileInput2(false);
  }, [webcamRef]);

  const dataURItoBlob = (dataURI) => {
    if (dataURI === null) {
      return null;
    }
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  const handleFileInputChange = (event, inputNumber) => {
    const selectedFile = event.target.files[0];
    setFilename1(selectedFile);
    if (selectedFile) {
      if (inputNumber === 1) {
        setFilename1(selectedFile);
        setFileValid1(selectedFile.size <= 300 * 1024);
        setShowFileInput2(false);
      } else if (inputNumber === 2) {
        setFileValid2(selectedFile.size <= 300 * 1024);
        setShowFileInput1(false);
      }
    }
  };

  //sms
  // const handleSendSMS = (phoneNumber) => {
  //   axios.post('/send-sms', { phoneNumber })
  //     .then(response => {
  //       console.log('SMS sent successfully:', response.data);
  //     })
  //     .catch(error => {
  //       console.error('Error sending SMS:', error);
  //     });
  //   }

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setQuery1(inputValue);

    const result = productdata1.filter((item) =>
      item.company_name.toLowerCase().includes(inputValue.toLowerCase())
    );

    setFilter1(result);

    const memberIds = result.map((item) => item.company_id);
    setCompanyid(memberIds);
  };

  const [filter1, setFilter1] = useState([]);
  const [Companyid, setCompanyid] = useState([]);
  const handleMemberSubmit = async (e) => {
    e.preventDefault();

    const url = "https://www.synologicsserver.in//newMember";
    const formData = new FormData();

    formData.append("company_name", query1);
    formData.append("company_id", Companyid);
    formData.append("payment", "0");
    formData.append("member_due_date", "0");
    formData.append("member_status", "0");
    formData.append("age1", formData2.age);
    formData.append("aadharNumber1", formData2.aadharNumber);
    formData.append("alternativeNumber1", formData2.alternativeNumber);
    formData.append("blood1", formData2.blood);
    formData.append("companyMember1", formData2.companyMember);
    formData.append("dateOfBirth1", formData2.dateOfBirth);
    formData.append("education1", formData2.education);
    formData.append("phoneNumber1", formData2.phoneNumber);
    formData.append("file1", filename1);
    // formData.append("member_id", MemberId);

    const validateForm = (formErrors) => {
      return Object.values(formErrors).some((error) => error);
    };
    const hasValidationErrors = validateForm(formErrors);
    if (hasValidationErrors) {
      swal({
        title: "Please fix the validation errors.",
        icon: "error",
        dangerMode: true,
      });
      return;
    }
    if (query1 !== "") {
      try {
        const response = await fetch(url, {
          method: "POST",
          body: formData,
        });

        const responseData = await response.json();

        if (response.ok) {
          swal({
            title: responseData.message,
            icon: "success",
            dangerMode: true,
          });
          setIsModalOpen(false);
          window.location.reload();
          // navigate("/SideBar/ApproveMember");
        } else {
          swal({
            title: responseData.message,
            icon: "error",
            dangerMode: true,
          });
        }
      } catch (error) {
        console.error("Fetch Error:", error);
        console.error("URL:", url);
      }
    } else {
      swal({
        title: "Please Enter Your credentials",
        icon: "error",
        dangerMode: true,
      });
    }
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `https://www.synologicsserver.in//edit_member?id=${formData1.member_id}`,
        {
          method: "PUT",
          body: JSON.stringify({
            company_id: formData1.company_id,
            company_name: formData1.company_name,
            member_name: formData1.companyMember,
            phone_number: formData1.phoneNumber,
            alter_number: formData1.alternativeNumber,
            dob: formData1.dateOfBirth,
            education: formData1.education,
            age: formData1.age,
            blood_group: formData1.blood,
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        if (data.success === 1) {
          swal({
            title: response.message,
            icon: "success",
            dangerMode: true,
          });
          setIsModalOpen1(false);
          window.location.reload();
        } else {
          console.error("Payment update failed.");
        }
      } else {
        console.error("HTTP request failed.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleEditSubmit1 = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `https://www.synologicsserver.in//Edit_company?id=${formData1.company_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            company_address: formData1.company_address,
            refference_num: formData1.refference_num,
            company_thaluk: formData1.company_thaluk,
            company_distric: formData1.company_distric,
            shop: formData1.shop,
          }),
        }
      );

      if (response.ok) {
        console.log("Payment submitted successfully!");
        setIsModalOpen1(true);
      } else {
        console.error("Payment submission failed.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handledelete = async (member_id, company_id) => {
    const confirmed = window.confirm("Are you sure you want to delete?");

    if (confirmed) {
      try {
        const memberResponse = await fetch(
          `https://www.synologicsserver.in//member_delete?id=${member_id}`,
          {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (memberResponse.ok) {
          console.log("Member deleted successfully");
          const companyResponse = await fetch(
            `https://www.synologicsserver.in//company_delete?id=${company_id}`,
            {
              method: "DELETE",
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          if (companyResponse.ok) {
            const companyData = await companyResponse.json();
            console.log("Company deleted successfully:", companyData);
          } else {
            const companyErrorData = await companyResponse.json();
            console.error("Error deleting company:", companyErrorData);
          }

          // Reload the page after successful deletion
          window.location.reload();
        } else {
          const memberErrorData = await memberResponse.json();
          console.error("Error deleting member:", memberErrorData);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  const sortedFilter = [...filter].sort((a, b) => b.id - a.id);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const currentPageData = sortedFilter.slice(startIndex, endIndex);

  const totalPages = Math.ceil(sortedFilter.length / itemsPerPage);
  // date

  // const handleDueDate = () => {
  //   let accumulatedStateDates = [];

  //   for (let i = 0; i < currentPageData.length; i++) {
  //     const newduedata = { ...currentPageData[i] };
  //     // console.log("jk", newduedata);

  //     var date2 = new Date();
  //     let newDate1 = moment(date2).format("DD-MM-YYYY");

  //     // Parse the member_due_date from the current item
  //     const parts = newduedata.member_due_date.split("-");
  //     const day1 = parseInt(parts[0], 10);
  //     const month1 = parseInt(parts[1], 10);
  //     const year1 = parseInt(parts[2], 10);
  //     const dueDate1 = new Date(year1, month1 - 1, day1);
  //     // console.log("dueDate1", dueDate1);

  //     // Calculate the difference in days between the due date and current date
  //     var Difference_In_Time = dueDate1.getTime() - date2.getTime();
  //     var Difference_In_Days = Math.floor(
  //       Difference_In_Time / (1000 * 3600 * 24)
  //     );
  //     console.log("Difference_In_Days",Difference_In_Days);
  //     const formattedDueDate = moment(dueDate1).format("DD-MM-YYYY");

  //     // Log information for debugging
  //      console.log(
  //        "object",
  //        (Difference_In_Days >= 0 && Difference_In_Days <= 5) ||
  //       currentDate === newduedata.member_due_date
  //       );

  //     let stateDate = "";

  //     // Determine the stateDate based on conditions
  //     if (
  //       (Difference_In_Days <= 5)||
  //        currentDate === newduedata.member_due_date

  //     ) {

  //       stateDate = "Unpaid";
  //     } else if (
  //       newduedata.member_paid <= 0 ||
  //       newduedata.member_due_date === 0 ||
  //       isNaN(Difference_In_Days)

  //     ) {
  //       // console.log(currentDate < newduedata.member_due_date);
  //       stateDate = "Due Date";;
  //     } else if (

  //       newduedata.member_paid === 1000 ||
  //       newduedata.memberStatus === 1
  //     ) {
  //       console.log("Paid condition:", newduedata.member_paid === 1000);
  //       stateDate = "Paid";
  //     }

  //     // Accumulate the state dates in the array
  //     accumulatedStateDates.push(stateDate);
  //   }

  //   // Set the state after the loop is complete
  //   setDueDateState(accumulatedStateDates);
  //   setRefresh(!refresh);
  // };

  const handleDueDate = () => {
    let accumulatedStateDates = [];

    for (let i = 0; i < currentPageData.length; i++) {
      const newduedata = { ...currentPageData[i] };
      var date2 = new Date();
      let newDate1 = moment(date2).format("DD-MM-YYYY");

      const parts = newduedata.member_due_date.split("-");
      const day1 = parseInt(parts[0], 10);
      const month1 = parseInt(parts[1], 10);
      const year1 = parseInt(parts[2], 10);
      const dueDate1 = new Date(year1, month1 - 1, day1);

      var Difference_In_Time = dueDate1.getTime() - date2.getTime();
      var Difference_In_Days = Math.floor(
        Difference_In_Time / (1000 * 3600 * 24)
      );

      const currentDate = moment(date2).format("DD-MM-YYYY");

      let stateDate = "";

      if (
        currentDate > newduedata.member_due_date ||
        Difference_In_Days <= 5 ||
        newduedata.member_due_date === "0" ||
        isNaN(Difference_In_Days)
      ) {
        stateDate = "Due Date";
      } else if (
        Difference_In_Days <= 5 ||
        currentDate === newduedata.member_due_date ||
        newduedata.member_paid <= 0
      ) {
        stateDate = "Unpaid";
      } else if (
        currentDate < newduedata.member_due_date ||
        newduedata.member_paid === 1000 ||
        newduedata.memberStatus === 1
      ) {
        stateDate = "Paid";
      }

      accumulatedStateDates.push(stateDate);
    }

    setDueDateState(accumulatedStateDates);
    setRefresh(!refresh);
  };

  return (
    <div className="p-4">
      <div className="">
        <div className="row m-0">
          <div className="col-md-12 card-member">
            <div className="col-md-9 d-flex align-items-center">
              <h1 className="head-fon-mv">View Members</h1>
            </div>
            <div className="row">
              <div className="items-per-page col-md-9 d-flex align-items-center p-3">
                <label>Show&nbsp;</label>
                <select
                  value={itemsPerPage}
                  onChange={(e) => setItemsPerPage(parseInt(e.target.value))}
                >
                  <option value="20">20</option>
                  <option value="40">40</option>
                  <option value="60">60</option>
                  <option value="80">80</option>
                </select>
                <label>&nbsp;entries</label>
              </div>

              <div className="col-md-3 d-flex align-items-center justify-content-end">
                <div className="searchbar">
                  <input
                    type="text"
                    className="search"
                    placeholder="Search"
                    onChange={(e) => handleInput(e)}
                  />
                  <FaSearch className="search-icon" />
                </div>
              </div>
            </div>
            {/* <div className={query.length ? "searchshow" : ""}>
              {query.length > 0 &&
                filter.map((pdata, index) => <p key={index}>{pdata.title}</p>)}
            </div> */}
            <div>
              <button
                onClick={() => setIsModalOpen(true)}
                className="sub-btn-member"
              >
                ADD
              </button>
            </div>
            <div className="table-container text-end mt-3">
              <FaFileExcel className="m-1 export-btn" />
              <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="download-table-xls-button export  mb-3"
                table="table-to-xls"
                filename="tablexls"
                sheet="tablexls"
                buttonText="Export to Excel "
              />
              <table id="table-to-xls">
                <thead>
                  <tr>
                    <th style={{ width: "4%" }}>s.no</th>
                    <th>Company Name</th>
                    <th>Member Id</th>
                    <th>Member Name</th>
                    <th>Phone No</th>
                    <th>Due Date</th>
                    <th style={{ width: "6%" }}>Status</th>
                    <th style={{ width: "6%" }}>View</th>
                    <th>Edit</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {currentPageData.map((member_details, index) => {
                    return (
                      <tr key={member_details.id}>
                        {/* <td>{index + 1}</td> */}
                        <td>{startIndex + index + 1}</td>
                        <td>{member_details.company_name}</td>
                        <td>{member_details.member_id}</td>
                        <td>{member_details.member_name}</td>
                        <td>{member_details.phone_number}</td>
                        <td>{member_details.member_due_date}</td>

                        <td>
                          {dueDateState[index] === "Paid" && (
                            <span className="paid">Paid</span>
                          )}
                          {dueDateState[index] === "Unpaid" && (
                            <span className="unpaid">Unpaid</span>
                          )}
                          {dueDateState[index] === "Due Date" && (
                            <span className="yellow">Due Date</span>
                          )}
                        </td>

                        <td>
                          <span
                            className="submit"
                            onClick={() => handleSubmit(member_details.id)}
                          >
                            <FaEye className="icon" />
                          </span>
                        </td>
                        <td>
                          <span
                            className="submit"
                            onClick={() =>
                              handleSubmit1(
                                member_details.member_id,
                                member_details.company_id
                              ) & setIsModalOpen1(true)
                            }
                          >
                            <FontAwesomeIcon icon={faEdit} />
                          </span>
                        </td>
                        <td>
                          <span
                            className="submit"
                            onClick={() =>
                              handledelete(
                                member_details.member_id,
                                member_details.company_id
                              )
                            }
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div
            className="dataTables_paginate paging_simple_numbers p-2"
            id="dtBasicExample_paginate"
          >
            <ul className="pagination">
              <li
                className={`paginate_button page-item ${
                  currentPage === 1 ? "disabled" : ""
                }`}
              >
                <button
                  onClick={() =>
                    setCurrentPage((prevPage) =>
                      prevPage > 1 ? prevPage - 1 : prevPage
                    )
                  }
                  className="page-link"
                >
                  Previous
                </button>
              </li>
              {generatePaginationNumbers().map((page, index) => (
                <li
                  key={index}
                  className={`paginate_button page-item ${
                    page === currentPage ? "active" : ""
                  }`}
                >
                  <button
                    onClick={() => setCurrentPage(page)}
                    className="page-link"
                  >
                    {page}
                  </button>
                </li>
              ))}
              <li
                className={`paginate_button page-item ${
                  currentPage === totalPages ? "disabled" : ""
                }`}
              >
                <button
                  onClick={() =>
                    setCurrentPage((prevPage) =>
                      prevPage < totalPages ? prevPage + 1 : prevPage
                    )
                  }
                  className="page-link"
                >
                  Next
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Modal
        isOpen={isModalOpen}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            width: "70%",
            margin: "auto",
            height: "100vh",
            display: "flex",
            overflow: "hidden",
            flexDirection: "column",
            justifyContent: "space-between",
          },
        }}
        onRequestClose={closeModal}
        contentLabel="Print Modal"
        ref={modalRef}
      >
        <form action="/create" method="post" encType="multipart/form-data">
          <div className="row mb-4">
            <div className="col-md-4">
              <label htmlFor="companyName" className="form-label">
                Company Name :
              </label>
              <input
                type="text"
                className="form-control"
                id="companyName"
                name="companyName"
                value={query1}
                onChange={handleInputChange}
                list="companies"
              />
              <datalist id="companies">
                {filter1.map((company) => (
                  <option key={company.id} value={company.company_name} />
                ))}
              </datalist>
            </div>
            <div className="col-md-4">
              <label htmlFor="companyId" className="form-label">
                Company Id:
              </label>
              <input
                type="text"
                className="form-control"
                id="companyId"
                name="companyId"
                value={Companyid}
                readOnly
              />
            </div>
            <div className="grid-container mt-4">
              <div className="col-lg-12 mb-3">
                <div className="fa-button d-flex justify-content-between">
                  <span className="mem-fon">Person </span>
                  <div></div>
                </div>
              </div>
              <div className="col-lg-12 ">
                <div className="row">
                  <div className="col-md-3 mb-3">
                    <label htmlFor="inputPassword4" className="form-label">
                      Company Member <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        formErrors.companyMember ? "border border-danger" : ""
                      }`}
                      id="inputPassword4"
                      name="companyMember"
                      value={formData2.companyMember}
                      onChange={handleInputChange1}
                      required
                    />
                  </div>

                  <div className="col-md-3 mb-3">
                    <label htmlFor="inputPassword4" className="form-label">
                      Aadhar Number <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        formErrors.aadharNumber ? "border border-danger" : ""
                      }`}
                      id="inputPassword4"
                      name="aadharNumber"
                      value={formData2.aadharNumber}
                      onChange={handleInputChange1}
                      required
                      maxLength={12}
                    />
                  </div>

                  <div className="col-md-3 mb-3">
                    <label htmlFor="inputPassword4" className="form-label">
                      Phone Number <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        formErrors.phoneNumber ? "border border-danger" : ""
                      }`}
                      id="inputPassword4"
                      name="phoneNumber"
                      value={formData2.phoneNumber}
                      onChange={handleInputChange1}
                      required
                      maxLength={10}
                    />
                  </div>
                  <div className="col-md-3 mb-3">
                    <label htmlFor="inputPassword4" className="form-label">
                      Alternative Number <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        formErrors.alternativeNumber
                          ? "border border-danger"
                          : ""
                      }`}
                      id="inputPassword4"
                      name="alternativeNumber"
                      value={formData2.alternativeNumber}
                      onChange={handleInputChange1}
                      required
                      maxLength={10}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-3 mb-3">
                    <label htmlFor="inputPassword4" className="form-label">
                      DOB <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      id="inputPassword4"
                      name="dateOfBirth"
                      value={formData2.dateOfBirth}
                      onChange={handleInputChange1}
                      required
                    />
                  </div>
                  <div className="col-md-3 mb-3">
                    <label htmlFor="inputPassword4" className="form-label">
                      Age <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        formErrors.age ? "border border-danger" : ""
                      }`}
                      id="inputPassword4"
                      name="age"
                      value={formData2.age}
                      onChange={handleInputChange1}
                      required
                      maxLength={3}
                    />
                  </div>
                  <div className="col-md-3 mb-3">
                    <label htmlFor="inputPassword4" className="form-label">
                      Education <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        formErrors.education ? "border border-danger" : ""
                      }`}
                      id="inputPassword4"
                      name="education"
                      value={formData2.education}
                      onChange={handleInputChange1}
                      required
                    />
                  </div>
                  <div className="col-md-3 mb-3">
                    <label htmlFor="inputPassword4" className="form-label">
                      Blood <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      className="form-select"
                      id="district"
                      value={formData2.blood}
                      onChange={handleInputBlood}
                      // style={{ borderColor: districValid ? "initial" : "red" }}
                      required
                    >
                      <option value="">Select a Blood Group</option>
                      {bloodGroup.map((option) => (
                        <option value={option.blood}>{option.blood}</option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="row">
                  {showFileInput1 && (
                    <div className="col-md-3 mb-3">
                      <label htmlFor="file1" className="form-label">
                        Upload File
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={filenameimage}
                      />
                      <div className="Container p-3">
                        {img3 === null ? (
                          <>
                            <Webcam
                              audio={false}
                              mirrored={true}
                              height={100}
                              width={150}
                              ref={webcamRef}
                              screenshotFormat="image/jpeg"
                              videoConstraints={videoConstraints}
                            />
                            <button
                              className="btn btn-success m-2"
                              onClick={capture}
                            >
                              Capture photo
                            </button>
                          </>
                        ) : (
                          <>
                            <div className="image-container">
                              <img
                                src={URL.createObjectURL(img3)}
                                alt="screenshot"
                              />
                              <button
                                className="btn-remove m-2"
                                onClick={() => {
                                  setImg3(null);
                                  setShowFileInput2(true);
                                  setFilenameimage("");
                                }}
                              >
                                Retake
                              </button>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  )}
                  {showFileInput2 && (
                    <div className="col-md-3 mb-3">
                      <label htmlFor="file2" className="form-label">
                        Upload File
                      </label>
                      <input
                        type="file"
                        name="file2"
                        className={`form-control ${
                          fileValid2 ? "" : "is-invalid"
                        }`}
                        id="myFile2"
                        accept=".jpg, .jpeg, .png,"
                        onChange={(e) => handleFileInputChange(e, 2)}
                      />
                      {!fileValid2 && (
                        <div className="invalid-feedback">
                          File size must be less than or equal to 250KB.
                        </div>
                      )}
                      <button
                        className="btn-remove"
                        onClick={() => {
                          setFilename1("");
                          setShowFileInput1(true);
                        }}
                      >
                        Remove
                      </button>
                    </div>
                  )}
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center">
                  <button
                    type="submit"
                    className="sub-btn-member"
                    onClick={(e) => handleMemberSubmit(e)}
                  >
                    Submit{" "}
                  </button>
                  {/* <button onClick={openModal}>Print</button> */}
                </div>
              </div>
            </div>
          </div>
        </form>
      </Modal>

      {/* modal1 edit */}
      <Modal
        isOpen={isModalOpen1}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            width: "70%",
            margin: "auto",
            height: "100%",
            display: "flex",
            overflow: "hidden",
            flexDirection: "column",
            justifyContent: "space-between",
          },
        }}
        onRequestClose={closeModal1}
        contentLabel="Print Modal"
        ref={modalRef1}
      >
        <form action="/create" method="post" encType="multipart/form-data">
          {/* <div className="fa-button d-flex justify-content-between">
              <span className="mem-fon">Company Name</span> */}

          <div className="row mb-4">
            {/* ... Company Name input */}

            <div className="col-md-4">
              <label htmlFor="companyName" className="form-label">
                Company Name :
              </label>
              <input
                type="text"
                // className="search"
                id="companyName"
                name="companyName"
                value={formData1.company_name}
                onChange={(e) =>
                  setFormData1({ ...formData1, company_name: e.target.value })
                }
                list="companies"
              />
              <datalist id="companies">
                {filter1.map((company) => (
                  <option key={company.id} value={company.company_name} />
                ))}
              </datalist>
            </div>
            <div className="col-md-4">
              <label htmlFor="companyId" className="form-label">
                Company Id:
              </label>
              <input
                type="text"
                id="companyId"
                name="companyId"
                value={formData1.company_id}
                onChange={(e) =>
                  setFormData1({ ...formData1, company_id: e.target.value })
                }
              />
            </div>
            <div className="grid-container mt-4">
              <div className="col-lg-12 mb-3">
                <div className="fa-button d-flex justify-content-between">
                  <span className="mem-fon">Person </span>
                  <div></div>
                </div>
              </div>
              <div className="col-lg-13 ">
                <div className="row">
                  <div className="col-md-3 mb-3">
                    <label htmlFor="inputPassword4" className="form-label">
                      Company Member <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        formErrors.companyMember ? "border border-danger" : ""
                      }`}
                      id="inputPassword4"
                      name="companyMember"
                      value={formData1.companyMember}
                      onChange={(e) =>
                        setFormData1({
                          ...formData1,
                          companyMember: e.target.value,
                        })
                      }
                      required
                    />
                  </div>
                  <div className="col-md-3 mb-3">
                    <label htmlFor="inputPassword4" className="form-label">
                      Member Id :<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        formErrors.companyMember ? "border border-danger" : ""
                      }`}
                      id="inputPassword4"
                      name="companyMember"
                      value={formData1.member_id}
                      onChange={(e) =>
                        setFormData1({
                          ...formData1,
                          member_id: e.target.value,
                        })
                      }
                      required
                    />
                  </div>
                  <div className="col-md-3 mb-3">
                    <label htmlFor="inputPassword4" className="form-label">
                      Aadhar Number <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        formErrors.aadharNumber ? "border border-danger" : ""
                      }`}
                      id="inputPassword4"
                      name="aadharNumber"
                      value={formData1.aadharNumber}
                      onChange={(e) =>
                        setFormData1({
                          ...formData1,
                          aadharNumber: e.target.value,
                        })
                      }
                      required
                      maxLength={12}
                    />
                  </div>

                  <div className="col-md-3 mb-3">
                    <label htmlFor="inputPassword4" className="form-label">
                      Phone Number <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        formErrors.phoneNumber ? "border border-danger" : ""
                      }`}
                      id="inputPassword4"
                      name="phoneNumber"
                      value={formData1.phoneNumber}
                      onChange={(e) =>
                        setFormData1({
                          ...formData1,
                          phoneNumber: e.target.value,
                        })
                      }
                      required
                      maxLength={10}
                    />
                  </div>
                  <div className="col-md-3 mb-3">
                    <label htmlFor="inputPassword4" className="form-label">
                      Alternative Number <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        formErrors.alternativeNumber
                          ? "border border-danger"
                          : ""
                      }`}
                      id="inputPassword4"
                      name="alternativeNumber"
                      value={formData1.alternativeNumber}
                      onChange={(e) =>
                        setFormData1({
                          ...formData1,
                          alternativeNumber: e.target.value,
                        })
                      }
                      required
                      maxLength={10}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-3 mb-3">
                    <label htmlFor="inputPassword4" className="form-label">
                      DOB <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      id="inputPassword4"
                      name="dateOfBirth"
                      value={formData1.dateOfBirth}
                      onChange={(e) =>
                        setFormData1({
                          ...formData1,
                          dateOfBirth: e.target.value,
                        })
                      }
                      required
                    />
                  </div>
                  <div className="col-md-3 mb-3">
                    <label htmlFor="inputPassword4" className="form-label">
                      Age <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        formErrors.age ? "border border-danger" : ""
                      }`}
                      id="inputPassword4"
                      name="age"
                      value={formData1.age}
                      onChange={(e) =>
                        setFormData1({ ...formData1, age: e.target.value })
                      }
                      required
                      maxLength={3}
                    />
                  </div>
                  <div className="col-md-3 mb-3">
                    <label htmlFor="inputPassword4" className="form-label">
                      Education <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      className={`form-control ${
                        formErrors.education ? "border border-danger" : ""
                      }`}
                      id="inputPassword4"
                      name="education"
                      value={formData1.education}
                      onChange={(e) =>
                        setFormData1({
                          ...formData1,
                          education: e.target.value,
                        })
                      }
                      required
                    />
                  </div>
                  <div className="col-md-3 mb-3">
                    <label htmlFor="inputPassword4" className="form-label">
                      Blood <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      className="form-select"
                      id="district"
                      value={formData1.blood}
                      onChange={(e) =>
                        setFormData1({ ...formData1, blood: e.target.value })
                      }
                      // style={{ borderColor: districValid ? "initial" : "red" }}
                      required
                    >
                      <option value="">Select a Blood Group</option>
                      {bloodGroup.map((option) => (
                        <option value={option.blood}>{option.blood}</option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-3 col-sm-3 mb-3">
                    <label className="form-label">
                      District <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      className="form-select"
                      id="district"
                      value={formData1.company_distric}
                      onChange={(e) =>
                        setFormData1({
                          ...formData1,
                          company_distric: e.target.value,
                        })
                      }
                      // style={{ borderColor: districValid ? "initial" : "red" }}
                      required
                    >
                      <option value="" disabled>
                        Select a district
                      </option>
                      {options
                        .slice() // Create a copy of the array to avoid modifying the original
                        .sort((a, b) => a.name.localeCompare(b.name)) // Sort alphabetically
                        .map((option) => (
                          <option key={option.id} value={option.name}>
                            {option.name}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="col-md-3 col-sm-3 mb-3 ">
                    <label for="inputPassword" className="form-label">
                      thaluk <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="tel"
                      inputMode="numeric"
                      className="form-control"
                      id="inputPassword"
                      value={formData1.company_thaluk}
                      onChange={(e) =>
                        setFormData1({
                          ...formData1,
                          company_thaluk: e.target.value,
                        })
                      }
                      maxLength={10}
                      required
                    />
                  </div>
                  <div className="col-md-3 col-sm-3 mb-3 ">
                    <label for="inputPassword" className="form-label">
                      Reference Number <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="tel"
                      inputMode="numeric"
                      className="form-control"
                      id="inputPassword"
                      value={formData1.refference_num}
                      onChange={(e) =>
                        setFormData1({
                          ...formData1,
                          refference_num: e.target.value,
                        })
                      }
                      maxLength={10}
                      required
                    />
                  </div>

                  <div className="col-md-3 col-sm-3 mb-3">
                    <div
                      className="form-check form-check-inline"
                      style={{ marginTop: "11%" }}
                    >
                      <input
                        className="form-check-input"
                        type="radio"
                        name="inlineRadioOption"
                        id="inlineRadio1"
                        value="Own Shop"
                        checked={formData1.shop === "Own Shop"}
                        onChange={(e) =>
                          setFormData1({ ...formData1, shop: e.target.value })
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="inlineRadio1"
                      >
                        Own Shop
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="inlineRadioOption"
                        id="inlineRadio2"
                        value="Rental Shop"
                        checked={formData1.shop === "Rental Shop"}
                        onChange={(e) =>
                          setFormData1({ ...formData1, shop: e.target.value })
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor="inlineRadio2"
                      >
                        Rental Shop
                      </label>
                    </div>
                  </div>

                  {/* <div className="col-md-3 col-sm-3 mb-3 ">
                    <label for="inputPassword" className="form-label">
                       <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="tel"
                      inputMode="numeric"
                      className="form-control"
                      id="inputPassword"
                      value={formData1.refference_num}
                      onChange={(e) => setFormData1({ ...formData1, refference_num: e.target.value })}
                      maxLength={10}
                      required
                    />
                  </div> */}
                  <div className="col-lg-6 col-md-6 col-sm-6 mb-3 ">
                    <label for="inputAddress" className="form-label">
                      Address <span style={{ color: "red" }}>*</span>
                    </label>
                    <textarea
                      type="text"
                      className="form-control"
                      id="inputAddress"
                      value={formData1.company_address}
                      onChange={(e) =>
                        setFormData1({
                          ...formData1,
                          company_address: e.target.value,
                        })
                      }
                      required
                    />
                  </div>
                </div>

                <div className="col-lg-17 col-md-12 col-sm-12 d-flex justify-content-center m-3">
                  <button
                    type="submit"
                    className="sub-btn-member"
                    onClick={(e) => {
                      handleEditSubmit1(e);
                      handleEditSubmit(e);
                    }}
                  >
                    Submit{" "}
                  </button>
                  {/* <button onClick={openModal}>Print</button> */}
                </div>
              </div>
            </div>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default ViewMember;
