import React, { useState, useEffect } from "react";
import { FaSearch } from "react-icons/fa";

function Report() {
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState([]);
  const [query, setQuery] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  console.log("totalAmount",totalAmount)
  const [searchedTotal, setSearchedTotal] = useState(null);

  const fetchData = async () => {
    try {
      const req = await fetch("https://www.synologicsserver.in/reports");
      const res = await req.json();
      setData(res);
      setFilter(res);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetch("https://www.synologicsserver.in/total_amt")
      .then((res) => res.json())
      .then((data) => {
        console.log("data",data.totalAmount)
        if (data.totalAmount && data.totalAmount && data.totalAmount) {
          setTotalAmount(data.totalAmount);
          console.log("Total Amount:", data.totalAmount);
        } else {
          console.error("No data or incorrect format received.");
        }
      })
      .catch((err) => console.error("Error fetching SQL data:", err));
  }, []);
  

  useEffect(() => {
    fetchData();
  }, []);

  const handleInput = async (e) => {
    const inputValue = e.target.value;
    setQuery(inputValue);
    const url = `https://www.synologicsserver.in/total_amt1`;
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          value: inputValue,
        }),
      });
      const data = await response.json();
      setSearchedTotal(data.totalWithFilter);

      if (response.ok) {
        console.error("Payment submission success.");
      } else {
        console.error("Payment submission failed.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
    const result = data.filter((item) => {
      const memberpaymode = item.paymode
        .toLowerCase()
        .includes(inputValue.toLowerCase());
        const memberdist = item.member_id
        .toLowerCase()
        .includes(inputValue.toLowerCase());
      return memberpaymode || memberdist;
    });

    setFilter(result);
  };

  return (
    <div className="p-4">
      <div>
       
        <label>Total Amount: {searchedTotal ? searchedTotal : totalAmount}</label>
      </div>
      <div className="col-md-12 m-3 d-flex align-items-center justify-content-end">
        <div className="searchbar">
          <input
            type="text"
            className="search"
            placeholder="Search"
            value={query}
            onChange={(e) => handleInput(e)}
          />
          <FaSearch className="search-icon" />
        </div>
      </div>
      <table className="report-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Company Name</th>
            <th>Member ID</th>
            <th>Member Paid Amount</th>
            <th>Payment Mode</th>
            <th>Total Amount</th>
          </tr>
        </thead>
        <tbody>
          {filter.map((item, i) => (
            <tr key={i}>
              <td>{item.id}</td>
              <td>{item.company_name}</td>
              <td>{item.member_id}</td>
              <td>{item.member_paidamt}</td>
              <td>{item.paymode}</td>
              <td>{item.total_amt}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Report;
