import React, { useState, useEffect, useRef, useCallback } from "react";
import "./Member.css";
import { FaCross, FaPlus, FaSearch } from "react-icons/fa";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import Multiselect from "multiselect-react-dropdown";
import Form from "react-bootstrap/Form";
import img from "../Images/logo.png";
// import Loading from 'react-loading-components';
// import LoadingSpin from "react-loading-spin";
import LoadingOverlay from "react-loading-overlay";

import Select from "react-select";
import Webcam from "react-webcam";
import Webcam1 from "react-webcam";
import ReactToPrint from "react-to-print";
import Modal from "react-modal";
import Text from "react-text";
function generateRandomString(length) {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let randomString = "";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomString += characters.charAt(randomIndex);
  }
  return randomString;
}

const options = [
  {
    id: "1",
    name: "AR-ARIYALUR",
    secname: "AR-",
  },
  {
    id: "2",
    name: "CG-CHENGALPATTU",
    secname: "CG-",
  },
  {
    id: "3",
    name: "CH-CHENNAI",
    secname: "CH-",
  },
  {
    id: "4",
    name: "CO-COIMBATORE",
    secname: "CO-",
  },
  {
    id: "5",
    name: "CU-CUDDALORE",
    secname: "CU-",
  },
  {
    id: "6",
    name: "DH-DHARMAPURI",
    secname: "DH-",
  },
  {
    id: "7",
    name: "DI-DINDIGUL",
    secname: "DI-",
  },
  {
    id: "8",
    name: "ER-ERODE",
    secname: "ER-",
  },
  {
    id: "9",
    name: "KL-KALLAKURICHI",
    secname: "KL-",
  },
  {
    id: "10",
    name: "KC-KANCHIPURAM",
    secname: "KC-",
  },
  {
    id: "11",
    name: "KK-KANNIYAKUMARI",
    secname: "Kk-",
  },
  {
    id: "12",
    name: "KA-KARUR",
    secname: "KR-",
  },
  {
    id: "13",
    name: "KR-KRISHNAGIRI",
    secname: "KR-",
  },
  {
    id: "14",
    name: "MA-MADURAI",
    secname: "MA-",
  },
  {
    id: "15",
    name: "MY-MAYILADUTHURAI",
    secname: "MY-",
  },
  {
    id: "16",
    name: "NG-NAGAPATTINAM",
    secname: "NG-",
  },
  {
    id: "17",
    name: "NM-NAMAKKAL",
    secname: "NM-",
  },
  {
    id: "18",
    name: "NI-NILGIRIS",
    secname: "Ni-",
  },
  {
    id: "19",
    name: "PE-PERAMBALUR",
    secname: "PE-",
  },
  {
    id: "20",
    name: "PU-PUDUKKOTTAI",
    secname: "PU-",
  },
  {
    id: "21",
    name: "RA-RAMANATHAPURAM",
    secname: "RA-",
  },
  {
    id: "22",
    name: "RN-RANIPET",
    secname: "RN-",
  },
  {
    id: "23",
    name: "SA-SALEM",
    secname: "SA-",
  },
  {
    id: "24",
    name: "SI-SIVAGANGAI",
    secname: "SI-",
  },
  {
    id: "25",
    name: "TS-THENKASI",
    secname: "TS-",
  },
  {
    id: "26",
    name: "TJ-THANJAVUR",
    secname: "TJ-",
  },
  {
    id: "27",
    name: "TH-THENI",
    secname: "TH-",
  },
  {
    id: "28",
    name: "TK-THOOTHUKUDI",
    secname: "TK-",
  },
  {
    id: "29",
    name: "TC-TIRUCHIRAPPALLI",
    secname: "TC-",
  },

  {
    id: "30",
    name: "TI-TIRUNELVELI",
    secname: "TI-",
  },
  {
    id: "31",
    name: "TU-TIRUPATHUR",
    secname: "TU-",
  },
  {
    id: "32",
    name: "TP-TIRUPPUR",
    secname: "TP-",
  },
  {
    id: "33",
    name: "TL-TIRUVALLUR",
    secname: "TL-",
  },
  {
    id: "34",
    name: "TV-TIRUVANNAMALAI",
    secname: "TV-",
  },
  {
    id: "35",
    name: "TR-TIRUVARUR",
    secname: "TR-",
  },
  {
    id: "36",
    name: "VE-VELLORE",
    secname: "VE-",
  },
  {
    id: "37",
    name: "VL-VILUPPURAM",
    secname: "VL-",
  },
  {
    id: "38",
    name: "VR-VIRUDHUNAGAR",
    secname: "VR-",
  },
];

const bloodGroup = [
  {
    blood: "A+",
  },
  {
    blood: "A-",
  },
  {
    blood: "B+",
  },
  {
    blood: "B-",
  },
  {
    blood: "O+",
  },
  {
    blood: "O-",
  },
  {
    blood: "AB+",
  },
  {
    blood: "AB-",
  },
];

const Member = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const modalRef = useRef(null);
  const componentRef = useRef(null);
  const navigate = useNavigate();
  const [memberName, setMemberName] = useState("");
  const [address, setAddress] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [district, setDistrict] = useState("");
  console.log("danger", district);
  const [webAddress, setwebAddress] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [companyYear, setCompanyyear] = useState("");
  const [locationValue, setLocationValue] = useState("");
  const [gstNo, setGstNo] = useState("");
  const [employee, setEmployee] = useState("");
  const [employeeTemp, setEmployeeTemp] = useState("");
  const [referenceNumber, setReferenceNumber] = useState("");
  const [Taluk, setTaluk] = useState("");
  console.log("Taluk", Taluk);
  const [selectedService, setSelectedService] = useState([]);
  //   console.log("darahh",selectedService);
  const [inputValue, setInputValue] = useState("");
  const [memberName1, setMemberName1] = useState("");
  const [memberNameValid1, setMemberNameValid1] = useState(true);
  const [showGSTINInput, setShowGSTINInput] = useState(false);
  const [memberNameValid, setMemberNameValid] = useState(true);
  const [companyvalid, setcompanyValid] = useState(true);
  const [addressValid, setAddressValid] = useState(true);
  const [locationvalid, setlocationValid] = useState(true);
  const [gstvalid, setgstValid] = useState(true);
  const [employeevalid, setEmployeeValid] = useState(true);
  const [emailnameValid, setEmailNameValid] = useState(true);
  const [webvalid, setWebValid] = useState(true);
  const [employeevalidTemp, setEmployeeValidTemp] = useState(true);
  const [referenceNumberValid, setReferenceNumberValid] = useState(true);
  const [TalukValid, setTalukValid] = useState(true);
  const [memberid, setMemberId] = useState("");
  console.log("memberid", memberid);
  const [districValid, setdistricValid] = useState(true);
  const [hide, setHide] = useState(true);
  const [hide1, setHide1] = useState(true);
  const [hide2, setHide2] = useState(true);
  const [hide3, setHide3] = useState(true);
  const [loading, setLoading] = useState(false);
  //company search

  const [query, setQuery] = useState("");
  const [filter, setFilter] = useState([]);
  const [productdata, setProductdata] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const req = await fetch("https://www.synologicsserver.in/user2");
        const res = await req.json();
        setProductdata(res);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
    const result = productdata.filter((item) =>
      item.company_name.toLowerCase().includes(query.toLowerCase())
    );
    setFilter(result);
  }, [query]);

  const handleChange = (e) => {
    const inputValue = e.target.value;
    setMemberName(inputValue);
    setMemberNameValid(inputValue !== "");
    setQuery(inputValue);
    // setMemberName(e.target.value);
    // setMemberNameValid(e.target.value !== "");
  };

  const generateRandomHash = () => {
    const randomString = generateRandomString(10);
    return randomString;
  };
  const [filename1, setFilename1] = useState("");
  // const [filename2, setFilename2] = useState("");
  // const [filename3, setFilename3] = useState("");
  // const [filename4, setFilename4] = useState("");

  const [filenameimage, setFilenameimage] = useState("");
  const [showFileInput1, setShowFileInput1] = useState(true);
  const [showFileInput2, setShowFileInput2] = useState(true);
  const [img3, setImg3] = useState(null);
  // const [img4, setImg4] = useState(null);
  // const [img5, setImg5] = useState(null);
  // const [img6, setImg6] = useState(null);
  console.log("hu", img3);
  const webcamRef = useRef(null);
  // const webcamRef1 = useRef(null);
  // const webcamRef2 = useRef(null);
  // const webcamRef3 = useRef(null);

  const videoConstraints = {
    width: 420,
    height: 420,
    facingMode: "user",
  };

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    const timestamp = Date.now();
    const filename = `${memberName}_${timestamp}.jpg`;
    const imageBlob = dataURItoBlob(imageSrc);
    const imageFile = new File([imageBlob], filename, { type: "image/jpeg" });
    setImg3(imageFile);
    setFilename1(imageFile);
    setFilenameimage(imageFile.name);
    setShowFileInput1(true);
    setShowFileInput2(false);
  }, [webcamRef]);

  const dataURItoBlob = (dataURI) => {
    if (dataURI === null) {
      return null;
    }
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };
  //   secondAddmember
  const webcamRef1 = useRef(null);
  const [filenameimage1, setFilenameimage1] = useState("");
  const [img4, setImg4] = useState(null);
  const [filename2, setFilename2] = useState("");
  const [showFileInput3, setShowFileInput3] = useState(true);
  const [showFileInput4, setShowFileInput4] = useState(true);
  const videoConstraints1 = {
    width: 420,
    height: 420,
    facingMode: "user",
  };

  const capture1 = useCallback(() => {
    const imageSrc = webcamRef1.current.getScreenshot();
    const timestamp = Date.now();
    const filename = `photo_${timestamp}.jpg`;

    const imageBlob = dataURItoBlob1(imageSrc);
    const imageFile = new File([imageBlob], filename, { type: "image/jpeg" });
    setImg4(imageFile);
    setFilename2(imageFile);
    setFilenameimage1(imageFile.name);
    setShowFileInput3(true);
    setShowFileInput4(false);
  }, [webcamRef1]);

  const dataURItoBlob1 = (dataURI) => {
    if (dataURI === null) {
      return null;
    }
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };
  //   thirdAddmember
  const webcamRef2 = useRef(null);
  const [filenameimage2, setFilenameimage2] = useState("");
  const [img5, setImg5] = useState(null);
  const [filename3, setFilename3] = useState("");
  const [showFileInput5, setShowFileInput5] = useState(true);
  const [showFileInput6, setShowFileInput6] = useState(true);

  const videoConstraints2 = {
    width: 420,
    height: 420,
    facingMode: "user",
  };

  const capture2 = useCallback(() => {
    const imageSrc = webcamRef2.current.getScreenshot();
    const timestamp = Date.now();
    const filename = `photo_${timestamp}.jpg`;

    const imageBlob = dataURItoBlob2(imageSrc);
    const imageFile = new File([imageBlob], filename, { type: "image/jpeg" });
    setImg5(imageFile);
    setFilename3(imageFile);
    setFilenameimage2(imageFile.name);
    setShowFileInput5(true);
    setShowFileInput6(false);
  }, [webcamRef2]);

  const dataURItoBlob2 = (dataURI) => {
    if (dataURI === null) {
      return null;
    }
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };
  //   fourAddmember
  const webcamRef3 = useRef(null);
  const [img6, setImg6] = useState(null);
  const [filenameimage3, setFilenameimage3] = useState("");
  const [filename4, setFilename4] = useState("");
  const [showFileInput7, setShowFileInput7] = useState(true);
  const [showFileInput8, setShowFileInput8] = useState(true);

  const videoConstraints3 = {
    width: 420,
    height: 420,
    facingMode: "user",
  };

  const capture3 = useCallback(() => {
    const imageSrc = webcamRef3.current.getScreenshot();
    const timestamp = Date.now();
    const filename = `photo_${timestamp}.jpg`;

    const imageBlob = dataURItoBlob3(imageSrc);
    const imageFile = new File([imageBlob], filename, { type: "image/jpeg" });
    setImg6(imageFile);
    setFilename4(imageFile);
    setFilenameimage3(imageFile.name);
    setShowFileInput7(true);
    setShowFileInput8(false);
  }, [webcamRef3]);

  const dataURItoBlob3 = (dataURI) => {
    if (dataURI === null) {
      return null;
    }
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  const [fileValid, setFileValid] = useState(true);
  const [fileValid1, setFileValid1] = useState(true);

  const [fileValid2, setFileValid2] = useState(true);
  const [file, setFile] = useState(null);

  const [fileValid3, setFileValid3] = useState(true);
  const [fileValid4, setFileValid4] = useState(true);

  const [selectedOptions, setSelectedOptions] = useState();

  // Array of all options
  const optionList = [
    { value: "1", label: "sound and light" },
    { value: "2", label: "Genset" },
    { value: "3", label: "shamiana" },
    { value: "4", label: "samayal paathirangal" },
    { value: "5", label: "super kattamaippu pantal" },
    { value: "6", label: "PVC Pantal" },
    { value: "7", label: "Keettru Pantal" },
    { value: "8", label: "Decoration" },
    { value: "9", label: "Malar alaṅkāram" },
    { value: "10", label: "Furniture" },
    { value: "11", label: " Pachai Pantal" },
    { value: "12", label: "GI Pantal" },
  ];

  // Function triggered on selection
  const handleSelect = (selectedOptions) => {
    const selectedLabels = selectedOptions.map((option) => option.label);
    console.log("Selected labels:", selectedLabels);
    setSelectedService(selectedLabels);
  };
  const handleSubmit = async (e) => {
    // setLoading(true);
    e.preventDefault();
    const randomHashcode = generateRandomHash();
    var date = new Date();
    var getYear = date.toLocaleString("default", { year: "numeric" });
    var getMonth = date.toLocaleString("default", { month: "2-digit" });
    var getDay = date.toLocaleString("default", { day: "2-digit" });
    var dateformat = getYear + "-" + getMonth + "-" + getDay;
    // const url1 = "http://localhost:3005/member_details";
    const url = "https://www.synologicsserver.in/create";

    const formData = new FormData();

    // Append data to formData here
    formData.append("company_id", memberid);
    formData.append("gstNo", gstNo);
    formData.append("payment", "0");
    formData.append("member_due_date", "0");
    formData.append("member_status", "0");
    formData.append("member_print", "1");
    formData.append("entery", "Offline");
    formData.append("companyYear", companyYear);
    formData.append("locationValue", locationValue);
    formData.append("memberName", memberName);
    formData.append("memberName1", memberName1);
    formData.append("employee", employee);
    formData.append("inputValue", selectedService);
    formData.append("web", webAddress);
    formData.append("employeeTemp", employeeTemp);
    formData.append("referenceNumber", referenceNumber);
    formData.append("taluk", Taluk);
    formData.append("district", district);
    formData.append("address", address);
    formData.append("emailAddress", emailAddress);
    formData.append("shop", selectedOption);
    // formData.append("file", file);
    // formData.append("file2", img4);
    formData.append("memberid", memberid);
    formData.append("age1", formData2.age);
    formData.append("aadharNumber1", formData2.aadharNumber);
    formData.append("alternativeNumber1", formData2.alternativeNumber);
    formData.append("blood1", formData2.blood);
    formData.append("companyMember1", formData2.companyMember);
    formData.append("dateOfBirth1", formData2.dateOfBirth);
    formData.append("education1", formData2.education);
    formData.append("phoneNumber1", formData2.phoneNumber);
    formData.append("file1", filename1);
    formData.append("file2", filename2);
    formData.append("file3", filename3);
    formData.append("file4", filename4);
    const additionalData = {
      age: formData1.age,
      aadharNumber: formData1.aadharNumber,
      alternativeNumber: formData1.alternativeNumber,
      blood: formData1.blood,
      companyMember: formData1.companyMember,
      dateOfBirth: formData1.dateOfBirth,
      education: formData1.education,
      phoneNumber: formData1.phoneNumber,
      state: formData.state,
    };
    const additionalData1 = {
      age: formData3.age,
      aadharNumber: formData3.aadharNumber,
      alternativeNumber: formData3.alternativeNumber,
      blood: formData3.blood,
      companyMember: formData3.companyMember,
      dateOfBirth: formData3.dateOfBirth,
      education: formData3.education,
      phoneNumber: formData3.phoneNumber,
      state: formData.state,
    };
    const additionalData2 = {
      age: formData4.age,
      aadharNumber: formData4.aadharNumber,
      alternativeNumber: formData4.alternativeNumber,
      blood: formData4.blood,
      companyMember: formData4.companyMember,
      dateOfBirth: formData4.dateOfBirth,
      education: formData4.education,
      phoneNumber: formData4.phoneNumber,
      state: formData.state,
    };

    formData.append("additionalData", JSON.stringify(additionalData));
    formData.append("additionalData1", JSON.stringify(additionalData1));
    formData.append("additionalData2", JSON.stringify(additionalData2));

    if (
      memberName !== "" &&
      memberName1 !== "" &&
      district !== "" &&
      formData.phoneNumber !== ""
    ) {
      try {
        const response = await fetch(url, {
          method: "POST",
          body: formData,
        });

        const responseData = await response.json();
        console.log("new", responseData);

        if (response.ok) {
          if (responseData.message === "Data inserted successfully") {
            setLoading(true);
            setTimeout(() => {
              setLoading(false);
              swal({
                title: responseData.message,
                icon: "success",
                dangerMode: true,
              }).then(() => {
                window.location.reload(); // Refresh the page
              });
              setIsModalOpen(true);
            }, 1000);
          } else {
            setLoading(true);
          }
        } else {
          swal({
            title: responseData.message,
            icon: "error",
            dangerMode: true,
          });
        }
      } catch (error) {
        console.error("Fetch Error:", error);
        console.error("URL:", url);
      }
    } else {
      swal({
        title: "Please Enter Your credentials",
        icon: "error",
        dangerMode: true,
      });
    }
  };

  const handleRadioChange = (e) => {
    if (e.target.value === "gstNo") {
      setShowGSTINInput(true);
    } else {
      setShowGSTINInput(false);
      setGstNo("");
    }
  };
  // const handleChangeSubmit = () => {
  //     setIsHidden(false);
  // };
  // const handleChangeSubmit3 = () => {
  //     setHidden(false);
  // };

  //   const handleServiceChange = (e) => {
  //     const selectedValue = e.target.value;
  //     if (selectedService === selectedValue) {
  //       setSelectedService("");
  //       setInputValue("");
  //     } else {
  //       setSelectedService(selectedValue);
  //       if (inputValue.includes(e.target.selectedOptions[0].text)) {
  //         setInputValue(inputValue.replace(e.target.selectedOptions[0].text, ""));
  //       } else {
  //         setInputValue(
  //           (prevValue) => prevValue + " " + e.target.selectedOptions[0].text
  //         );
  //       }
  //     }
  //   };
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const validategst = (gst) => {
    const gstRegex = /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/;
    return gstRegex.test(gst);
  };
  const handleDistrictChange = (e) => {
    const selectedDistrict = e.target.value;
    setDistrict(selectedDistrict);
    setTaluk("");
    setTalukValid(true);

    const selectedOption = options.find(
      (option) => option.name === selectedDistrict
    );

    if (selectedOption) {
      const randomNum = Math.floor(Math.random() * 100);
      const updatedSecName = `${selectedOption.secname}`;
      console.log("tal", Taluk);
      setMemberId(updatedSecName);
    }

    setdistricValid(selectedDistrict !== "");
  };

  const handleRadioChange1 = (e) => {
    setSelectedOption(e.target.value);
  };
  const [formData2, setFormData2] = useState({
    companyMember: "",
    aadharNumber: "",
    phoneNumber: "",
    alternativeNumber: "",
    age: "",
    blood: "",
    dateOfBirth: "",
    education: "",
    file1: null,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData2({
      ...formData2,
      [name]: value,
    });
  };
  const handleInputChange1 = (e) => {
    const { name, value } = e.target;
    setFormData1({
      ...formData1,
      [name]: value,
    });
  };
  const handleInputChange3 = (e) => {
    const { name, value } = e.target;
    setFormData3({
      ...formData3,
      [name]: value,
    });
  };
  const handleInputChange4 = (e) => {
    const { name, value } = e.target;
    setFormData4({
      ...formData4,
      [name]: value,
    });
  };
  const handleInputBlood = (e) => {
    const selectedOptionName = e.target.value;
    setFormData2({ ...formData2, blood: selectedOptionName });
  };
  const handleInputBlood1 = (e) => {
    const selectedOptionName = e.target.value;
    setFormData1({ ...formData1, blood: selectedOptionName });
  };
  const handleInputBlood3 = (e) => {
    const selectedOptionName = e.target.value;
    setFormData3({ ...formData3, blood: selectedOptionName });
  };
  const handleInputBlood4 = (e) => {
    const selectedOptionName = e.target.value;
    setFormData4({ ...formData4, blood: selectedOptionName });
  };
  // const handleFileChange = (e) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     if (file.size > 150 * 1024) {
  //       setFileValid2(false);
  //     } else {
  //       setFileValid2(true);
  //     }
  //     setFormData2({
  //       ...formData2,
  //       file1: file,
  //     });
  //   }
  // };
  // const handleFileChange1 = (e) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     if (file.size > 250 * 1024) {
  //       // 150 KB in bytes
  //       setFileValid1(false);
  //     } else {
  //       setFileValid1(true);
  //     }
  //     setFormData1({
  //       ...formData1,
  //       file2: file,
  //     });
  //   }
  // };
  // const handleFileChange3 = (e) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     if (file.size > 250 * 1024) {
  //       // 150 KB in bytes
  //       setFileValid3(false);
  //     } else {
  //       setFileValid3(true);
  //     }
  //     setFormData3({
  //       ...formData1,
  //       file3: file,
  //     });
  //   }
  // };
  // const handleFileChange4 = (e) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     if (file.size > 250 * 1024) {
  //       // 150 KB in bytes
  //       setFileValid4(false);
  //     } else {
  //       setFileValid4(true);
  //     }
  //     setFormData4({
  //       ...formData4,
  //       file4: file,
  //     });
  //   }
  // };

  const [formData1, setFormData1] = useState({
    companyMember: "",
    aadharNumber: "",
    phoneNumber: "",
    alternativeNumber: "",
    age: "",
    blood: "",
    dateOfBirth: "",
    education: "",
    file2: null,
    state: "0",
  });
  console.log({ data1111: formData2.state });
  const [formData3, setFormData3] = useState({
    companyMember: "",
    aadharNumber: "",
    phoneNumber: "",
    alternativeNumber: "",
    age: "",
    blood: "",
    dateOfBirth: "",
    education: "",
    file3: null,
    state: "0",
  });
  const [formData4, setFormData4] = useState({
    companyMember: "",
    aadharNumber: "",
    phoneNumber: "",
    alternativeNumber: "",
    age: "",
    blood: "",
    dateOfBirth: "",
    education: "",
    file4: null,
    member_status: 0,
  });
  // const [formData5, setFormData5] = useState({
  //     companyMember: '',
  //     aadharNumber: '',
  //     phoneNumber: '',
  //     alternativeNumber: '',
  //     age: '',
  //     blood: '',
  //     dateOfBirth: '',
  //     education: '',
  //     file5: null,

  // });

  // const handleChange = (e) => {
  //     const { name, value } = e.target;
  //     setFormData({ ...formData, [name]: value });
  // };

  // const handleAddMember = () => {
  //     if (
  //         formData.companyMember &&
  //         formData.aadharNumber &&
  //         formData.phoneNumber &&
  //         formData.alternativeNumber &&
  //         formData.age &&
  //         formData.blood &&
  //         formData.dateOfBirth &&
  //         formData.education &&
  //         formData.uploadedFiles
  //     ) {
  //         setMembers([...members, formData]);
  //         setFormData({
  //             companyMember: '',
  //             aadharNumber: '',
  //             phoneNumber: '',
  //             alternativeNumber: '',
  //             age: '',
  //             blood: '',
  //             dateOfBirth: '',
  //             education: '',
  //             uploadedFiles: '',
  //         });
  //         setFileValid(true);
  //     } else {
  //         alert('Please fill in all required fields');
  //     }
  // };

  // const handleRemoveMember = (index) => {
  //     const updatedMembers = [...members];
  //     updatedMembers.splice(index, 1);
  //     setMembers(updatedMembers);
  // };

  // const handleFileChange2 = (e) => {
  //     const file2 = e.target.files[0];
  //     if (file2 && file2.size <= 250 * 1024) {
  //         setFile2(file2);
  //         setFileValid(true);
  //     } else {
  //         setFileValid(false);
  //     }
  // };

  // const handleFileChange2 = (e) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     if (file.size > 800 * 1024) {
  //       // 150 KB in bytes
  //       setFileValid(false);
  //     } else {
  //       setFileValid(true);
  //     }
  //     setFile(file);
  //    first addmember choosefile
  const handleFileInputChange = (event, inputNumber) => {
    const selectedFile = event.target.files[0];
    setFilename1(selectedFile);
    if (selectedFile) {
      if (inputNumber === 1) {
        setFilename1(selectedFile);
        setFileValid1(selectedFile.size <= 350 * 1024);
        setShowFileInput2(false);
      } else if (inputNumber === 2) {
        setFileValid2(selectedFile.size <= 350 * 1024);
        setShowFileInput1(false);
      }
    }
  };

  //    second addmember choosefile
  const handleFileInputChange1 = (event, inputNumber) => {
    const selectedFile = event.target.files[0];
    setFilename2(selectedFile);
    if (selectedFile) {
      if (inputNumber === 1) {
        setFilename2(selectedFile);
        setFileValid1(selectedFile.size <= 300 * 1024);
        setShowFileInput4(false);
      } else if (inputNumber === 2) {
        setFileValid2(selectedFile.size <= 300 * 1024);
        setShowFileInput3(false);
      }
    }
  };

  //    third addmember choosefile

  const handleFileInputChange2 = (event, inputNumber) => {
    const selectedFile = event.target.files[0];
    setFilename3(selectedFile);
    if (selectedFile) {
      if (inputNumber === 1) {
        setFilename3(selectedFile);
        setFileValid1(selectedFile.size <= 300 * 1024);
        setShowFileInput6(false);
      } else if (inputNumber === 2) {
        setFileValid2(selectedFile.size <= 300 * 1024);
        setShowFileInput5(false);
      }
    }
  };

  //    fourth addmember choosefile
  const handleFileInputChange3 = (event, inputNumber) => {
    const selectedFile = event.target.files[0];
    setFilename4(selectedFile);
    if (selectedFile) {
      if (inputNumber === 1) {
        setFilename4(selectedFile);
        setFileValid1(selectedFile.size <= 300 * 1024);
        setShowFileInput8(false);
      } else if (inputNumber === 2) {
        setFileValid2(selectedFile.size <= 300 * 1024);
        setShowFileInput7(false);
      }
    }
  };

  const handlehide = () => {
    setHide(false);
  };
  const handlehide1 = () => {
    setHide1(false);
  };
  const handlehide2 = () => {
    setHide2(false);
  };
  const handlehide3 = () => {
    setHide3(false);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <div className="p-4 outer-div">
      <div className="out-div ">
        {" "}
        <LoadingOverlay active={loading} spinner text="Loading...">
          {/* <div className="head-con">
        <div>
          {" "}
          <img src={img} className="member-logo1"></img>
        </div>
        <div>
          <h2>Thamizhaga Hire Goods Owners Association </h2>
          <h5>No:1, Jayachiman street,Avarampalayam,Coimbatore-641006</h5>
          <h3>Registration Form</h3>
          <h6>( Members only Allowed for Exhibition ) </h6>
        </div>
        <div>
          <img src={img1} className="member-logo2"></img>
        </div>
      </div> */}
          <div className="outer-con">
            <div className="head-form ">
              {/* <div className="login1-benten">
            <button
              type="submit"
              className="login-bttn"
              onClick={handlelogout}
            >
              {" "}
              Log Out
            </button>
          </div> */}
            </div>

            <form action="/create" method="post" encType="multipart/form-data">
              <div className="row mb-4">
                <div className="col-lg-12 head-container">
                  <div className="row">
                    <div className="col-md-6">
                      <label className="form-label">
                        Company Name <span style={{ color: "red" }}>*</span>
                      </label>
                      <div className="searchbar  d-flex align-items-center">
                        <input
                          type="text"
                          className="search"
                          value={memberName}
                          onChange={handleChange}
                          list="companies"
                        />
                        <datalist id="companies">
                          {filter.map((company) => (
                            <option
                              key={company.id}
                              value={company.company_name}
                            />
                          ))}
                        </datalist>
                        {/* <FaSearch /> */}
                      </div>
                    </div>

                    <div className="col-md-6">
                      <label htmlFor="inputEmail4" className="form-label">
                        Company Owner <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputEmail4"
                        value={memberName1}
                        onChange={(e) => {
                          setMemberName1(e.target.value);
                          setMemberNameValid1(e.target.value !== "");
                        }}
                        style={{
                          borderColor: memberNameValid1 ? "initial" : "red",
                        }}
                        required
                      />
                    </div>
                  </div>
                </div>
              </div>
              <hr></hr>
              {/*START ADD MEMBER     */}
              <div className="grid-container mt-4">
                <div className="col-lg-12 mb-3">
                  <div className="fa-button d-flex justify-content-between">
                    <span className="mem-fon">Person </span>
                    <div>
                      {/* <button
                    type="button"
                    className="btn btn-danger m-2 "
                    onClick={() =>setIsHidden(true) }
                  >
                    X
                  </button> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 ">
                  <div className="row">
                    <div className="col-md-3 mb-3">
                      <label htmlFor="inputPassword4" className="form-label">
                        Company Member <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputPassword4"
                        name="companyMember"
                        value={formData2.companyMember}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="col-md-3 mb-3">
                      <label htmlFor="inputPassword4" className="form-label">
                        Aadhar Number <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputPassword4"
                        name="aadharNumber"
                        value={formData2.aadharNumber}
                        onChange={handleInputChange}
                        required
                        maxLength={12}
                      />
                    </div>

                    <div className="col-md-3 mb-3">
                      <label htmlFor="inputPassword4" className="form-label">
                        Phone Number <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputPassword4"
                        name="phoneNumber"
                        value={formData2.phoneNumber}
                        onChange={handleInputChange}
                        required
                        maxLength={10}
                      />
                    </div>
                    <div className="col-md-3 mb-3">
                      <label htmlFor="inputPassword4" className="form-label">
                        Alternative Number{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputPassword4"
                        name="alternativeNumber"
                        value={formData2.alternativeNumber}
                        onChange={handleInputChange}
                        required
                        maxLength={10}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-3 mb-3">
                      <label htmlFor="inputPassword4" className="form-label">
                        DOB <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        id="inputPassword4"
                        name="dateOfBirth"
                        value={formData2.dateOfBirth}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="col-md-3 mb-3">
                      <label htmlFor="inputPassword4" className="form-label">
                        Age <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputPassword4"
                        name="age"
                        value={formData2.age}
                        onChange={handleInputChange}
                        required
                        maxLength={3}
                      />
                    </div>
                    <div className="col-md-3 mb-3">
                      <label htmlFor="inputPassword4" className="form-label">
                        Education <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputPassword4"
                        name="education"
                        value={formData2.education}
                        onChange={handleInputChange}
                        required
                      />
                    </div>
                    <div className="col-md-3 mb-3">
                      <label htmlFor="inputPassword4" className="form-label">
                        Blood <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        className="form-select"
                        id="district"
                        value={formData2.blood}
                        onChange={handleInputBlood}
                        style={{
                          borderColor: districValid ? "initial" : "red",
                        }}
                        required
                      >
                        <option value="">Select a Blood Group</option>
                        {bloodGroup.map((option) => (
                          <option value={option.blood}>{option.blood}</option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="row">
                    {/* <div className="col-md-3 mb-3">
                    <label htmlFor="file1" className="form-label">
                      Upload File
                    </label>
                    <input
                      className="form-control "
                      type="text"
                      value={filename1}
                    />
                    {!fileValid2 && (
                      <div className="invalid-feedback">
                        File size must be less than or equal to 250KB.
                      </div>
                    )}
                  </div> */}
                    <div
                      className="col-md-12"
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "center",
                      }}
                    >
                      <button
                        type="button"
                        className="add-btn"
                        onClick={handlehide}
                      >
                        {" "}
                        <FaPlus style={{ marginRight: "5px" }} />
                        Add person
                      </button>
                    </div>
                  </div>

                  <div className="row">
                    {showFileInput1 && (
                      <div className="col-md-3 mb-3">
                        <label htmlFor="file1" className="form-label">
                          Upload File
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={filenameimage}
                        />
                        <div className="Container p-3">
                          {img3 === null ? (
                            <>
                              <Webcam
                                audio={false}
                                mirrored={true}
                                height={200}
                                width={200}
                                ref={webcamRef}
                                screenshotFormat="image/jpeg"
                                videoConstraints={videoConstraints}
                              />
                              <button
                                className="btn btn-success m-4"
                                onClick={capture}
                              >
                                Capture photo
                              </button>
                            </>
                          ) : (
                            <>
                              <div className="image-container">
                                <img
                                  src={URL.createObjectURL(img3)}
                                  alt="screenshot"
                                />
                                <button
                                  className="btn-remove m-2"
                                  onClick={() => {
                                    setImg3(null);
                                    setShowFileInput2(true);
                                    setFilenameimage("");
                                  }}
                                >
                                  Retake
                                </button>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    )}
                    {showFileInput2 && (
                      <div className="col-md-3 mb-3">
                        <label htmlFor="file2" className="form-label">
                          Upload File
                        </label>
                        <input
                          type="file"
                          name="file2"
                          className={`form-control ${
                            fileValid2 ? "" : "is-invalid"
                          }`}
                          id="myFile2"
                          accept=".jpg, .jpeg, .png,"
                          onChange={(e) => handleFileInputChange(e, 2)}
                        />
                        {!fileValid2 && (
                          <div className="invalid-feedback">
                            File size must be less than or equal to 250KB.
                          </div>
                        )}
                        <button
                          className="btn-remove"
                          onClick={() => {
                            setFilename1("");
                            setShowFileInput1(true);
                          }}
                        >
                          Remove
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <hr></hr>
              {!hide && (
                <div className="col-lg-12 ">
                  <span className="mem-fon">Person 1</span>
                  <div style={{ display: "flex", justifyContent: "end" }}>
                    <button
                      type="button"
                      className="cls-btn m-2 "
                      onClick={() => setHide(true)}
                    >
                      X
                    </button>
                  </div>
                  <div></div>
                  <div className="row">
                    <div className="col-md-3 mb-3">
                      <label htmlFor="inputPassword4" className="form-label">
                        Company Member <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputPassword4"
                        name="companyMember"
                        value={formData1.companyMember}
                        onChange={handleInputChange1}
                        required
                      />
                    </div>
                    <div className="col-md-3 mb-3">
                      <label htmlFor="inputPassword4" className="form-label">
                        Aadhar Number <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputPassword4"
                        name="aadharNumber"
                        value={formData1.aadharNumber}
                        onChange={handleInputChange1}
                        required
                        maxLength={12}
                      />
                    </div>

                    <div className="col-md-3 mb-3">
                      <label htmlFor="inputPassword4" className="form-label">
                        Phone Number <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputPassword4"
                        name="phoneNumber"
                        value={formData1.phoneNumber}
                        onChange={handleInputChange1}
                        required
                        maxLength={10}
                      />
                    </div>
                    <div className="col-md-3 mb-3">
                      <label htmlFor="inputPassword4" className="form-label">
                        Alternative Number{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputPassword4"
                        name="alternativeNumber"
                        value={formData1.alternativeNumber}
                        onChange={handleInputChange1}
                        required
                        maxLength={10}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-3 mb-3">
                      <label htmlFor="inputPassword4" className="form-label">
                        DOB <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        id="inputPassword4"
                        name="dateOfBirth"
                        value={formData1.dateOfBirth}
                        onChange={handleInputChange1}
                        required
                      />
                    </div>
                    <div className="col-md-3 mb-3">
                      <label htmlFor="inputPassword4" className="form-label">
                        Age <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputPassword4"
                        name="age"
                        value={formData1.age}
                        onChange={handleInputChange1}
                        required
                        maxLength={3}
                      />
                    </div>
                    <div className="col-md-3 mb-3">
                      <label htmlFor="inputPassword4" className="form-label">
                        Education <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputPassword4"
                        name="education"
                        value={formData1.education}
                        onChange={handleInputChange1}
                        required
                      />
                    </div>
                    <div className="col-md-3 mb-3">
                      <label htmlFor="inputPassword4" className="form-label">
                        Blood <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        className="form-select"
                        id="district"
                        value={formData1.blood}
                        onChange={handleInputBlood1}
                        style={{
                          borderColor: districValid ? "initial" : "red",
                        }}
                        required
                      >
                        <option value="">Select a Blood Group</option>
                        {bloodGroup.map((option) => (
                          <option value={option.blood}>{option.blood}</option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="row">
                    {/* <div className="col-md-3 mb-3">
                    <label htmlFor="file1" className="form-label">
                      Upload File
                    </label>
                    <input
                      className="form-control "
                      type="text"
                      value={filename2}
                    />
                    {!fileValid1 && (
                      <div className="invalid-feedback">
                        File size must be less than or equal to 250KB.
                      </div>
                    )}
                  </div> */}
                    <div
                      className="col-md-12"
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "center",
                      }}
                    >
                      <button
                        type="button"
                        className="add-btn"
                        onClick={handlehide1}
                      >
                        {" "}
                        <FaPlus style={{ marginRight: "5px" }} />
                        Add person
                      </button>
                    </div>
                    <div className="row">
                      {showFileInput3 && (
                        <div className="col-md-3 mb-3">
                          <label htmlFor="file1" className="form-label">
                            Upload File
                          </label>
                          {/* <input
                                            type="file"
                                            name="file1"
                                            className={`form-control ${fileValid1 ? '' : 'is-invalid'}`}
                                            id="myFile1"
                                            accept=".jpg, .jpeg, .png,"
                                            onChange={(e) => handleFileInputChange(e, 1)}
                                        />
                                        {!fileValid1 && (
                                            <div className="invalid-feedback">
                                                File size must be less than or equal to 250KB.
                                            </div>
                                        )} */}
                          <input
                            type="text"
                            className="form-control"
                            value={filenameimage1}
                          />
                          <div className="Container m-4">
                            {img4 === null ? (
                              <>
                                <Webcam
                                  audio={false}
                                  mirrored={true}
                                  height={200}
                                  width={200}
                                  ref={webcamRef1}
                                  screenshotFormat="image/jpeg"
                                  videoConstraints={videoConstraints1}
                                />
                                <button
                                  className="btn btn-success m-4"
                                  onClick={capture1}
                                >
                                  Capture photo
                                </button>
                              </>
                            ) : (
                              <>
                                <img
                                  src={URL.createObjectURL(img4)}
                                  alt="screenshot"
                                />
                                <button
                                  className="btn-remove"
                                  onClick={() => {
                                    setImg4(null);
                                    setShowFileInput4(true);
                                    setFilenameimage1("");
                                  }}
                                >
                                  Retake
                                </button>
                              </>
                            )}
                          </div>
                        </div>
                      )}

                      {showFileInput4 && (
                        <div className="col-md-3 mb-3">
                          <label htmlFor="file2" className="form-label">
                            Upload File
                          </label>
                          <input
                            type="file"
                            name="file2"
                            className={`form-control ${
                              fileValid2 ? "" : "is-invalid"
                            }`}
                            id="myFile2"
                            accept=".jpg, .jpeg, .png,"
                            onChange={(e) => handleFileInputChange1(e, 2)}
                          />
                          {!fileValid2 && (
                            <div className="invalid-feedback">
                              File size must be less than or equal to 250KB.
                            </div>
                          )}
                          <button
                            className="btn-remove"
                            onClick={() => {
                              setFilename2("");
                              setShowFileInput3(true);
                            }}
                          >
                            Remove
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  <hr></hr>
                </div>
              )}

              {!hide1 && (
                <div className="col-lg-12 ">
                  <span className="mem-fon">Person 2</span>
                  <div style={{ display: "flex", justifyContent: "end" }}>
                    <button
                      type="button"
                      className="cls-btn m-2 "
                      onClick={() => setHide1(true)}
                    >
                      X
                    </button>
                  </div>
                  <div></div>
                  <div className="row">
                    <div className="col-md-3 mb-3">
                      <label htmlFor="inputPassword4" className="form-label">
                        Company Member <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputPassword4"
                        name="companyMember"
                        value={formData3.companyMember}
                        onChange={handleInputChange3}
                        required
                      />
                    </div>
                    <div className="col-md-3 mb-3">
                      <label htmlFor="inputPassword4" className="form-label">
                        Aadhar Number <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputPassword4"
                        name="aadharNumber"
                        value={formData3.aadharNumber}
                        onChange={handleInputChange3}
                        required
                      />
                    </div>

                    <div className="col-md-3 mb-3">
                      <label htmlFor="inputPassword4" className="form-label">
                        Phone Number <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputPassword4"
                        name="phoneNumber"
                        value={formData3.phoneNumber}
                        onChange={handleInputChange3}
                        required
                      />
                    </div>
                    <div className="col-md-3 mb-3">
                      <label htmlFor="inputPassword4" className="form-label">
                        Alternative Number{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputPassword4"
                        name="alternativeNumber"
                        value={formData3.alternativeNumber}
                        onChange={handleInputChange3}
                        required
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-3 mb-3">
                      <label htmlFor="inputPassword4" className="form-label">
                        DOB <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        id="inputPassword4"
                        name="dateOfBirth"
                        value={formData3.dateOfBirth}
                        onChange={handleInputChange3}
                        required
                      />
                    </div>
                    <div className="col-md-3 mb-3">
                      <label htmlFor="inputPassword4" className="form-label">
                        Age <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputPassword4"
                        name="age"
                        value={formData3.age}
                        onChange={handleInputChange3}
                        required
                      />
                    </div>
                    <div className="col-md-3 mb-3">
                      <label htmlFor="inputPassword4" className="form-label">
                        Education <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputPassword4"
                        name="education"
                        value={formData3.education}
                        onChange={handleInputChange3}
                        required
                      />
                    </div>
                    <div className="col-md-3 mb-3">
                      <label htmlFor="inputPassword4" className="form-label">
                        Blood <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        className="form-select"
                        id="blood"
                        value={formData3.blood}
                        onChange={handleInputBlood3}
                        // style={{ borderColor: districValid ? "initial" : "red" }}
                        required
                      >
                        <option value="">Select a Blood Group</option>
                        {bloodGroup.map((option) => (
                          <option value={option.blood}>{option.blood}</option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="row">
                    {/* <div className="col-md-3 mb-3">
                    <label htmlFor="file1" className="form-label">
                      Upload File
                    </label>
                    <input
                      className="form-control "
                      type="text"
                      value={filename3}
                    />
                    {!fileValid3 && (
                      <div className="invalid-feedback">
                        File size must be less than or equal to 250KB.
                      </div>
                    )}
                  </div> */}
                    <div
                      className="col-md-12"
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "center",
                      }}
                    >
                      <button
                        type="button"
                        className="add-btn"
                        onClick={handlehide3}
                      >
                        {" "}
                        <FaPlus style={{ marginRight: "5px" }} />
                        Add person
                      </button>
                    </div>
                    <div className="row">
                      {showFileInput5 && (
                        <div className="col-md-3 mb-3">
                          <label htmlFor="file1" className="form-label">
                            Upload File
                          </label>
                          {/* <input
                                            type="file"
                                            name="file1"
                                            className={`form-control ${fileValid1 ? '' : 'is-invalid'}`}
                                            id="myFile1"
                                            accept=".jpg, .jpeg, .png,"
                                            onChange={(e) => handleFileInputChange(e, 1)}
                                        />
                                        {!fileValid1 && (
                                            <div className="invalid-feedback">
                                                File size must be less than or equal to 250KB.
                                            </div>
                                        )} */}
                          <input
                            type="text"
                            className="form-control"
                            value={filenameimage2}
                          />
                          <div className="Container m-4">
                            {img5 === null ? (
                              <>
                                <Webcam
                                  audio={false}
                                  mirrored={true}
                                  height={200}
                                  width={200}
                                  ref={webcamRef2}
                                  screenshotFormat="image/jpeg"
                                  videoConstraints={videoConstraints2}
                                />
                                <button
                                  className="btn btn-success m-4"
                                  onClick={capture2}
                                >
                                  Capture photo
                                </button>
                              </>
                            ) : (
                              <>
                                <img
                                  src={URL.createObjectURL(img5)}
                                  alt="screenshot"
                                />
                                <button
                                  className="btn-remove"
                                  onClick={() => {
                                    setShowFileInput6(true);
                                    setImg5(null);
                                  }}
                                >
                                  Retake
                                </button>
                              </>
                            )}
                          </div>
                        </div>
                      )}

                      {showFileInput6 && (
                        <div className="col-md-3 mb-3">
                          <label htmlFor="file2" className="form-label">
                            Upload File
                          </label>
                          <input
                            type="file"
                            name="file2"
                            className={`form-control ${
                              fileValid2 ? "" : "is-invalid"
                            }`}
                            id="myFile2"
                            accept=".jpg, .jpeg, .png,"
                            onChange={(e) => handleFileInputChange2(e, 2)}
                          />
                          {!fileValid2 && (
                            <div className="invalid-feedback">
                              File size must be less than or equal to 250KB.
                            </div>
                          )}
                          <button
                            className="btn-remove"
                            onClick={() => {
                              setFilename3("");
                              setShowFileInput5(true);
                            }}
                          >
                            Remove
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  <hr></hr>
                </div>
              )}
              {/* <button
                    type="button"
                    className="btn btn-danger m-2 "
                    onClick={() =>setIsHidden(true) }
                  >
                    X
                  </button> */}
              {!hide3 && (
                <div className="col-lg-12 ">
                  <span className="mem-fon">Person 3</span>
                  <div style={{ display: "flex", justifyContent: "end" }}>
                    <button
                      type="button"
                      className="cls-btn m-2 "
                      onClick={() => setHide3(true)}
                    >
                      X
                    </button>
                  </div>
                  <div className="row">
                    <div className="col-md-3 mb-3">
                      <label htmlFor="inputPassword4" className="form-label">
                        Company Member <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputPassword4"
                        name="companyMember"
                        value={formData4.companyMember}
                        onChange={handleInputChange4}
                        required
                      />
                    </div>
                    <div className="col-md-3 mb-3">
                      <label htmlFor="inputPassword4" className="form-label">
                        Aadhar Number <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputPassword4"
                        name="aadharNumber"
                        value={formData4.aadharNumber}
                        onChange={handleInputChange4}
                        required
                      />
                    </div>

                    <div className="col-md-3 mb-3">
                      <label htmlFor="inputPassword4" className="form-label">
                        Phone Number <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputPassword4"
                        name="phoneNumber"
                        value={formData4.phoneNumber}
                        onChange={handleInputChange4}
                        required
                      />
                    </div>
                    <div className="col-md-3 mb-3">
                      <label htmlFor="inputPassword4" className="form-label">
                        Alternative Number{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputPassword4"
                        name="alternativeNumber"
                        value={formData4.alternativeNumber}
                        onChange={handleInputChange4}
                        required
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-3 mb-3">
                      <label htmlFor="inputPassword4" className="form-label">
                        DOB <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        id="inputPassword4"
                        name="dateOfBirth"
                        value={formData4.dateOfBirth}
                        onChange={handleInputChange4}
                        required
                      />
                    </div>
                    <div className="col-md-3 mb-3">
                      <label htmlFor="inputPassword4" className="form-label">
                        Age <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputPassword4"
                        name="age"
                        value={formData4.age}
                        onChange={handleInputChange4}
                        required
                      />
                    </div>
                    <div className="col-md-3 mb-3">
                      <label htmlFor="inputPassword4" className="form-label">
                        Education <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputPassword4"
                        name="education"
                        value={formData4.education}
                        onChange={handleInputChange4}
                        required
                      />
                    </div>
                    <div className="col-md-3 mb-3">
                      <label htmlFor="inputPassword4" className="form-label">
                        Blood <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        className="form-select"
                        id="blood"
                        value={formData4.blood}
                        onChange={handleInputBlood4}
                        style={{
                          borderColor: districValid ? "initial" : "red",
                        }}
                        required
                      >
                        <option value="">Select a Blood Group</option>
                        {bloodGroup.map((option) => (
                          <option value={option.blood}>{option.blood}</option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="row">
                    {showFileInput7 && (
                      <div className="col-md-3 mb-3">
                        <label htmlFor="file1" className="form-label">
                          Upload File
                        </label>
                        {/* <input
                                            type="file"
                                            name="file1"
                                            className={`form-control ${fileValid1 ? '' : 'is-invalid'}`}
                                            id="myFile1"
                                            accept=".jpg, .jpeg, .png,"
                                            onChange={(e) => handleFileInputChange(e, 1)}
                                        />
                                        {!fileValid1 && (
                                            <div className="invalid-feedback">
                                                File size must be less than or equal to 250KB.
                                            </div>
                                        )} */}
                        <input
                          type="text"
                          className="form-control"
                          value={filenameimage3}
                        />
                        <div className="Container m-4">
                          {img6 === null ? (
                            <>
                              <Webcam
                                audio={false}
                                mirrored={true}
                                height={200}
                                width={200}
                                ref={webcamRef3}
                                screenshotFormat="image/jpeg"
                                videoConstraints={videoConstraints3}
                              />
                              <button
                                className="btn btn-success m-4"
                                onClick={capture3}
                              >
                                Capture photo
                              </button>
                            </>
                          ) : (
                            <>
                              <img
                                src={URL.createObjectURL(img6)}
                                alt="screenshot"
                              />
                              <button
                                className="btn-remove"
                                onClick={() => {
                                  setShowFileInput8(true);
                                  setImg6(null);
                                }}
                              >
                                Retake
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                    )}
                    {showFileInput8 && (
                      <div className="col-md-3 mb-3">
                        <label htmlFor="file2" className="form-label">
                          Upload File
                        </label>
                        <input
                          type="file"
                          name="file2"
                          className={`form-control ${
                            fileValid2 ? "" : "is-invalid"
                          }`}
                          id="myFile2"
                          accept=".jpg, .jpeg, .png,"
                          onChange={(e) => handleFileInputChange3(e, 2)}
                        />
                        {!fileValid2 && (
                          <div className="invalid-feedback">
                            File size must be less than or equal to 250KB.
                          </div>
                        )}
                        <button
                          className="btn-remove"
                          onClick={() => {
                            setFilename4("");
                            setShowFileInput7(true);
                          }}
                        >
                          Remove
                        </button>
                      </div>
                    )}
                  </div>
                  <hr></hr>
                </div>
              )}

              {/* END ADD MEMBER */}

              <div className="col-lg-12  col-md-12 col-sm-12 mt-10">
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-6 mb-3 ">
                    <label for="inputAddress" className="form-label">
                      Address <span style={{ color: "red" }}>*</span>
                    </label>
                    <textarea
                      type="text"
                      className="form-control"
                      id="inputAddress"
                      //   placeholder="Address"
                      value={address}
                      onChange={(e) => {
                        setAddress(e.target.value);
                        setAddressValid(e.target.value !== "");
                      }}
                      style={{ borderColor: addressValid ? "initial" : "red" }}
                      required
                    />
                  </div>
                  <div className="col-lg-6  col-md-6 col-sm-6 mb-3">
                    <label className="form-label">Services</label>
                    <Select
                      options={optionList}
                      placeholder="Select Services"
                      value={optionList.filter((option) =>
                        selectedService.includes(option.label)
                      )}
                      onChange={handleSelect}
                      isSearchable={true}
                      isMulti
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3   col-sm-3  mb-3">
                    <label for="inputEmail4" className="form-label">
                      Email <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="inputEmail4"
                      value={emailAddress}
                      onChange={(e) => {
                        setEmailAddress(e.target.value);
                        setEmailNameValid(validateEmail(e.target.value));
                      }}
                      style={{
                        borderColor: emailnameValid ? "initial" : "red",
                      }}
                      required
                    />
                  </div>
                  <div className="col-md-3 col-sm-3 mb-3">
                    <label for="inputWeb1" className="form-label">
                      Web <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="inputWeb1"
                      value={webAddress}
                      onChange={(e) => {
                        setwebAddress(e.target.value);
                        setWebValid(e.target.value !== "");
                      }}
                      style={{ borderColor: webvalid ? "initial" : "red" }}
                      required
                    />
                  </div>

                  <div className="col-md-3 col-sm-3 mb-3">
                    <label className="form-label">
                      District <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      className="form-select"
                      id="district"
                      value={district}
                      onChange={handleDistrictChange}
                      style={{ borderColor: districValid ? "initial" : "red" }}
                      required
                    >
                      <option value="" disabled>
                        Select a district
                      </option>
                      {options
                        .slice() // Create a copy of the array to avoid modifying the original
                        .sort((a, b) => a.name.localeCompare(b.name)) // Sort alphabetically
                        .map((option) => (
                          <option key={option.id} value={option.name}>
                            {option.name}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="col-md-3 col-sm-3 mb-3 ">
                    <label for="inputPassword4" className="form-label">
                      Thaluk <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      className="form-control"
                      id="inputPassword4"
                      value={Taluk}
                      onChange={(e) => {
                        setTaluk(e.target.value);
                        setTalukValid(e.target.value !== "");
                      }}
                      style={{ borderColor: TalukValid ? "initial" : "red" }}
                      required
                    >
                      <option value="">Select A Thaluk</option>
                      {district === "AR-ARIYALUR" && (
                        <>
                          <option value="AR-ARIYALUR">Ariyalur</option>
                          <option value="UD-UDAYARPALAYAM">
                            Udayarpalayam
                          </option>
                          <option value="SE-SENDURAI">Sendurai</option>
                          <option value="AN-AANDIMADAM">Andimadam</option>
                          <option value="TI-TIRUMANUR">Tirumanur</option>
                          <option value="JY-JAYANKONDAM">Jayankondam</option>
                          <option value="TP-T. PALUR">T. Palur</option>
                        </>
                      )}
                      {district === "CG-CHENGALPATTU" && (
                        <>
                          <option value="CH-CHENGALPATTU">Chengalpattu</option>
                          <option value="MA-MADURANTHAKAM">Madurantakam</option>
                          <option value="TA-TAMBARAM">Tambaram</option>
                          <option value="TP-THIRUPORUR">Thiruporur</option>
                          <option value="TH-THIRUKAZHUKUNDRAM">
                            Thirukazhukundram
                          </option>
                          <option value="PA-PALLAVARAM">Pallavaram</option>
                        </>
                      )}
                      {district === "CH-CHENNAI" && (
                        <>
                          <option value="TI-TIRUVOTTIYUR">Tiruvottiyur</option>
                          <option value="MD-MADHAVARAM">Madhavaram</option>
                          <option value="TO-TONDIARPET">Tondiarpet</option>
                          <option value="PE-PERAMBUR">Perambur</option>
                          <option value="AM-AMBATTUR">Ambattur</option>
                          <option value="AY-AYANAVARAM">Ayanavaram</option>
                          <option value="PU-PURASAWALKAM">Purasawalkam</option>
                          <option value="EG-EGMORE">Egmore</option>
                          <option value="MY-MYLAPORE">Mylapore</option>
                          <option value="GU-GUINDY">Guindy</option>
                          <option value="MV-MADURAVOYAL">Maduravoyal</option>
                          <option value="MA-MAMBALAM">Mambalam</option>
                          <option value="AL-ALANDUR">Alandur</option>
                          <option value="VE-VELACHERY">Velachery</option>
                          <option value="SH-SHOLINGANALLUR">
                            Sholinganallur
                          </option>
                          <option value="CH-CHENNAI">Chennai</option>
                        </>
                      )}
                      {district === "CO-COIMBATORE" && (
                        <>
                          <option value="AM-ANAIMALAI">Anaimalai</option>
                          <option value="MA-MADDUKARAI">Madukkarai</option>
                          <option value="ME-METTUPALAYAM">Mettupalayam</option>
                          <option value="PO-POLLACHI"> Pollachi </option>
                          <option value="AN-ANNUR">Annur</option>
                          <option value="KI-KINATHUKADAVU">
                            Kinathukadavu
                          </option>
                          <option value="SU-SULUR">Sulur</option>
                          <option value="PE-PERUR">Perur</option>

                          <option value="VA-VALPARAI">Valparai</option>
                          <option value="WE-COIMBATORE WEST">
                            {" "}
                            Coimbatore West
                          </option>
                          <option value="ES-COIMBATORE EAST">
                            Coimbatore East
                          </option>
                          <option value="SO-COIMBATORE SOUTH ">
                            Coimbatore South
                          </option>
                          <option value="NO-COIMBATORE NORTH">
                            Coimbatore North
                          </option>
                          <option value="Coimbatore South"></option>
                          <option value="MI-COIMBATORE MIDDLE">
                            Coimbatore Middle
                          </option>
                        </>
                      )}
                      {district === "CU-CUDDALORE" && (
                        <>
                          <option value="CU-CUDDALORE">Cuddalore</option>
                          <option value="PA-PANRUTI">Panruti</option>
                          <option value="CH-CHIDAMBARAM">Chidambaram</option>
                          <option value="KA-KATTUMANNARKOVIL">
                            Kattumannarkoil
                          </option>
                          <option value="KU-KURINJIPADI">Kurinjipadi</option>
                          <option value="TI-TITAKUDI">Titakudi</option>
                          <option value="VR-VIRUDHACHALAM">Vriddachalam</option>
                          <option value="VE-VEPPUR">Veppur</option>
                          <option value="SR-SRIMUSHNAM">Srimushnam</option>
                          <option value="BH-BHUVANAGIRI">Bhuvanagiri</option>
                        </>
                      )}
                      {district === "DH-DHARMAPURI" && (
                        <>
                          <option value="DH-DHARMAPURI">Dharmapuri</option>
                          <option value="PA-PALACODE">Palacode</option>
                          <option value="PE-PENNAGARAM">Pennagaram</option>
                          <option value="HA-HARUR">Harur</option>
                          <option value="PP-PAPPIREDDIPATTI ">
                            Pappireddipatti
                          </option>
                          <option value="KA-KARIMANGALAM">Karimangalam</option>
                          <option value="NA-NALLAMPALLI">Nallampalli</option>
                        </>
                      )}
                      {district === "DI-DINDIGUL" && (
                        <>
                          <option value="AT-ATHOOR">Athoor</option>
                          <option value="DI-DINDIGUL">Dindigul</option>
                          <option value="KO-KODAIKANAL">Kodaikanal</option>
                          <option value="NA-NATHAM">Natham</option>
                          <option value="NI-NILAKOTTAI">Nilakottai</option>
                          <option value="OD-ODDANCHATTIRAM">
                            Oddanchattiram
                          </option>
                          <option value="PA-PALANI">Palani</option>
                          <option value="VE-VEDASANDUR">Vedasandur</option>
                        </>
                      )}
                      {district === "ER-ERODE" && (
                        <>
                          <option value="AN-ANTHIYUR">Anthiyur</option>
                          <option value="BH-BHAVANI">Bhavani</option>
                          <option value="ER-ERODE">Erode</option>
                          <option value="GO-GOBICHETTIPALAYAM">
                            Gobichettipalayam
                          </option>
                          <option value="KO-KODUMUDI">Kodumudi</option>
                          <option value="MO-MODAKURICHI">Modakurichi</option>
                          <option value="NA-NAMBIYUR">Nambiyur</option>
                          <option value="PE-PERUNDURAI">Perundurai</option>
                          <option value="SA-SATHYAMANGALAM">
                            Sathyamangalam
                          </option>
                          <option value="TH-THALAVADI">Thalavadi</option>
                          <option value="SI-SIVAGIRI">Sivagiri</option>
                          <option value="KV-KAVINDAPADI">Kavindapadi</option>
                        </>
                      )}
                      {district === "KL-KALLAKURICHI" && (
                        <>
                          <option value="KA-KALLAKURICHI">Kallakkurichi</option>
                          <option value="KD-KANDACHIPURAM">
                            Kandachipuram
                          </option>
                          <option value="UL-ULUNDHURPETTAI">Ulundurpet</option>
                          <option value="SA-SANGARAPURAM">Sankarapuram</option>
                          <option value="CH-CHINNASALEM">Chinnasalem</option>
                          <option value="TI-TIRUKOVILUR">Tirukoilur</option>
                          <option value="TH-THIYAGADURGAM">
                            Thiyagadurgam
                          </option>
                        </>
                      )}
                      {district === "KC-KANCHIPURAM" && (
                        <>
                          <option value="KA-KANCHIPURAM">Kanchipuram</option>
                          <option value="SR-SRIPERUMPATHUR">
                            Sriperumbudur
                          </option>
                          <option value="UT-UTHIRAMERUR">Uthiramerur</option>
                          <option value="WA-WALAJABAD">Walajabad</option>
                        </>
                      )}
                      {district === "KA-KARUR" && (
                        <>
                          <option value="AR-ARAVAKURICHI">Aravakurichi</option>
                          <option value="KD-KADAVUR">Kadavur</option>
                          <option value="KD-KADAVUR">Karur</option>
                          <option value="KP-KRISHNARAYAPURAM">
                            Krishnarayapuram
                          </option>
                          <option value="KU-KULITHALAI">Kulithalai</option>
                          <option value="MA-MANMANGALAM">Manmangalam</option>
                          <option value="PU-PUGALUR">Pugalur</option>
                        </>
                      )}
                      {district === "KR-KRISHNAGIRI" && (
                        <>
                          <option value="KR-KRISHNAGIRI">Krishnagiri</option>
                          <option value="HO-HOSUR">Hosur</option>
                          <option value="PO-POCHAMPALLI">Pochampalli</option>
                          <option value="UT-UTHANGARAI">Uthangarai</option>
                          <option value="DE-DENKANIKOTTAI">
                            Denkanikottai
                          </option>
                          <option value="AN-ANCHETTY">Anchetty</option>
                          <option value="SH-SOOLAGIRI">Shoolagiri</option>
                          <option value="BA-BARGUR">Bargur</option>
                        </>
                      )}
                      {district === "MA-MADURAI" && (
                        <>
                          <option value="MA-MADURAI">Madurai</option>
                          <option value="KA-KALLIKUDI">Kallikudi</option>
                          <option value="ME-MELUR">Melur</option>
                          <option value="PE-PERAIYUR">Peraiyur</option>
                          <option value="TH-THIRUMANGALAM">
                            Thirumangalam
                          </option>
                          <option value="TR-THIRUPPARANKUNDRAM">
                            Thirupparankundram
                          </option>
                          <option value="US-USILAMPATTI">Usilampatti</option>
                          <option value="VA-VADIPATTI">Vadipatti</option>
                        </>
                      )}
                      {district === "MY-MAYILADUTHURAI" && (
                        <>
                          <option value="KU-KUTHALAM">Kuthalam</option>
                          <option value="MY-MAYILADUTHURAI">
                            Mayiladuthurai
                          </option>
                          <option value="SI-SIRKAZHI">Sirkazhi</option>
                          <option value="TH-THARANGAMBADI">
                            Tharangambadi
                          </option>
                          <option value="SE-SEMPANNARKOVIL">
                            Sempannarkovil
                          </option>
                          <option value="KO-KOLLIDAM">Kollidam</option>
                        </>
                      )}
                      {district === "NG-NAGAPATTINAM" && (
                        <>
                          <option value="KI-KELVELUR">Kilvelur</option>
                          <option value="NA-NAGAPATTINAM">Nagapattinam</option>
                          <option value="TH-THIRUKKUVALAI">
                            Thirukkuvalai
                          </option>
                          <option value="VE-VEDARANYAM">Vedaranyam</option>
                          <option value="KU-KUTHALAM (N)"> Kuthalam (N)</option>
                          <option value="MA-MAYILADUTHURAI">
                            Mayiladuthurai
                          </option>
                          <option value="SI-SIRKAZHI (N)"> Sirkazhi (N)</option>
                        </>
                      )}
                      {district === "NM-NAMAKKAL" && (
                        <>
                          <option value="KM-KOLLIMALAI">Kollimalli</option>
                          <option value="MO-MOHANUR">Mohanur</option>
                          <option value="NA-NAMAKKAL">Namakkal</option>
                          <option value="PA-PARAMATHI VELUR ">
                            Paramathi-Velur
                          </option>
                          <option value="SE-SENTHAMANGALAM">
                            Sendamangalam
                          </option>
                          <option value="TH-TIRUCHENGODE">Thiruchengode</option>
                          <option value="RA-RASIPURAM">Rasipuram</option>
                          <option value="KO-KOMARAPALAYAM">
                            Komarapalayam
                          </option>
                          <option value="PM-PALLIPALAYAM">Pallipalayam</option>
                        </>
                      )}

                      {district === "NI-NILGIRIS" && (
                        <>
                          <option value="CO-COONOOR">Coonoor</option>
                          <option value="CO-COONOOR">Gudalur</option>
                          <option value="KO-KOTAGIRI">Kotagiri</option>
                          <option value="KU-KUNDAH">Kundah</option>
                          <option value="PA-PANDALUR">Pandalur</option>
                          <option value="UD-UDAGAMANDALAM">
                            Udagamandalam
                          </option>
                        </>
                      )}

                      {district === "PE-PERAMBALUR" && (
                        <>
                          <option value="AL-ALATHUR">Alathur</option>
                          <option value="KU-KUNNAM">Kunnam</option>
                          <option value="PE-PERAMBALUR">Perambalur</option>
                          <option value="VE-VEPPANTHATTAI">
                            Veppanthattai
                          </option>
                        </>
                      )}

                      {district === "PU-PUDUKKOTTAI" && (
                        <>
                          <option value="AL-ALANGUDI">Alangudi</option>
                          <option value="AR-ARANTHANGI">Aranthangi</option>
                          <option value="AV-AVADAIYARKOVIL">
                            Avadaiyarkoil
                          </option>
                          <option value="GA-GANDARVAKKOTTAI">
                            Gandarvakottai
                          </option>
                          <option value="IL-ILLUPPUR">Illuppur</option>
                          <option value="IL-ILLUPPUR">Karambakkudi</option>
                          <option value="KU-KULATHUR">Kulathur</option>
                          <option value="MA-MANAMELKUDI">Manamelkudi-PU</option>
                          <option value="PU-PUDUKOTTAI">Pudukottai</option>
                          <option value="TH-THIRUMAYAM">Thirumayam</option>
                          <option value="PO-PONNAMARAVATHY">
                            Ponnamaravathy
                          </option>
                          <option value="VI-VIRALIMALAI">Viralimalai</option>
                        </>
                      )}
                      {district === "RA-RAMANATHAPURAM" && (
                        <>
                          <option value="KD-KADALADI">Kadaladi</option>
                          <option value="KA-KAMUTHI">Kamuthi</option>
                          <option value="KI-KILAKARAI">Kilakarai</option>
                          <option value="MU-MUTHUKULATHUR">Mudukulathur</option>
                          <option value="PA-PARAMAKUDI">Paramakudi</option>
                          <option value="RA-RAMANATHAPURAM">
                            Ramanathapuram
                          </option>
                          <option value="RM-RAMESWARAM">Rameswaram</option>
                          <option value="TI-THIRUVADANAI">Tiruvadanai</option>
                          <option value="RS-RAJA SINGA MANGALAM">
                            Raja Singa Mangalam
                          </option>
                        </>
                      )}

                      {district === "RN-RANIPET" && (
                        <>
                          <option value="AK-ARAKKONAM">Arakkonam</option>
                          <option value="AR-ARCOT">Arcot</option>
                          <option value="NE-NEMILI">Nemili</option>
                          <option value="WA-WALAJAPET">Walajapet</option>
                        </>
                      )}

                      {district === "SA-SALEM" && (
                        <>
                          <option value="AT-ATTUR ">Attur</option>
                          <option value="ID-EDAPPADI">Idappadi</option>
                          <option value="GA-GANGAVALLI">Gangavalli</option>
                          <option value="KA-KADYAMPATTI">Kadyampatti</option>
                          <option value="MR-METTUR">Mettur</option>
                          <option value="OM-OMALUR">Omalur</option>
                          <option value="PE-PETHANAICKENPALAYAM">
                            Pethanaickenpalayam
                          </option>
                          <option value="SA-SALEM">Salem</option>
                          <option value="SG-SANKAGIRI">Sankagiri</option>
                          <option value="VA-VAZHAPADI">Valapady</option>
                          <option value="YE-YERCAUD">Yercaud</option>
                          <option value="KO-KOLATHUR">Kolathur</option>
                        </>
                      )}
                      {district === "SI-SIVAGANGAI" && (
                        <>
                          <option value="DE-DEVAKOTTAI">Devakottai</option>
                          <option value="IL-ILAYANGUDI">Ilayangudi</option>
                          <option value="KK-KALAYARKOIL">Kalayarkoil</option>
                          <option value="KA-KARAIKUDI">Karaikudi</option>
                          <option value="MA-MANAMADURAI">Manamadurai</option>
                          <option value="SI-SIVAGANGAI">Sivagangai</option>
                          <option value="SP-SINGAMPUNARI">Singampunari</option>
                          <option value="TI-TIRUPATTUR">Tirupathur</option>
                          <option value="TV-TIRUPPUVANAM">Tiruppuvanam</option>
                        </>
                      )}

                      {district === "TS-THENKASI" && (
                        <>
                          <option value="AL-ALANGULAM">Alangulam</option>
                          <option value="TE-THENKASI">Tenkasi</option>
                          <option value="KA-KADAYANALLUR">Kadayanallur</option>
                          <option value="SH-SENKOTTAI">Shenkottai</option>
                          <option value="VE-VEERAKERALAMPUDUR">
                            Veerakeralampudur
                          </option>
                          <option value="TH-THIRUVENKATAM">
                            Thiruvenkatam
                          </option>
                        </>
                      )}

                      {district === "TJ-THANJAVUR" && (
                        <>
                          <option value="BU-BUDALUR">Budalur</option>
                          <option value="KU-KUMBAKONAM">Kumbakonam</option>
                          <option value="OR-ORATHANADU">Orathanadu</option>
                          <option value="PP-PAPANASAM">Papanasam</option>
                          <option value="PA-PATTUKKOTTAI">Pattukkottai</option>
                          <option value="PE-PERAVURANI">Peravurani</option>
                          <option value="TH-THANJAVUR">Thanjavur</option>
                          <option value="TY-THIRUVAIYARU">Thiruvaiyaru</option>
                          <option value="TM-THIRUVIDAIMARUDUR">
                            Thiruvidaimarudur
                          </option>
                        </>
                      )}

                      {district === "TH-THENI" && (
                        <>
                          <option value="TH-THENI">Theni</option>
                          <option value="PE-PERIYAKULAM">Periyakulam</option>
                          <option value="AN-ANDIPATTI">Andipatti</option>
                          <option value="BO-BODINAYAKKANUR">
                            Bodniayakkanur
                          </option>
                          <option value="UT-UTHAMAPALAYAM">
                            Uthamapalayam
                          </option>
                        </>
                      )}

                      {district === "TK-THOOTHUKUDI" && (
                        <>
                          <option value="TH-THOOTHUKUDI">Thoothukudi</option>
                          <option value="ER-ERAL">Eral</option>
                          <option value="TI-TIRUCHENDUR">Tiruchendur</option>
                          <option value="OT-OTTAPIDARAM">Ottapidaram</option>
                          <option value="VI-VILATHIKULAM">Vilathikulam</option>
                          <option value="KO-KOVILPATTI">Kovilpatti</option>
                          <option value="KA-KAYUTHAR">Kayathur</option>
                          <option value="ET-ETTAIYAPURAM">Ettayapuram</option>
                          <option value="SA-SATHANKULAM">Sathankulam</option>
                          <option value="TK-TIRUVAIKUNTAM">
                            Tiruvaikuntam
                          </option>
                        </>
                      )}

                      {district === "TC-TIRUCHIRAPPALLI" && (
                        <>
                          <option value="LA-LALGUDI">Lalgudi</option>
                          <option value="MA-MANACHANALLUR">
                            Manachanallur
                          </option>
                          <option value="MP-MANAPPARAI">Manapparai</option>
                          <option value="MG-MARUNGAPURI">Marungapuri</option>
                          <option value="MU-MUSIRI">Musiri</option>
                          <option value="SR-SRIRANGAM">Srirangam</option>
                          <option value="TI-TIRUCHIRAPPALLI">
                            Tiruchirappalli
                          </option>
                          <option value="TV-THIRUVERUMBUR">
                            Thiruverumbur
                          </option>
                          <option value="TV-THIRUVERUMBUR">Thottiyam</option>
                          <option value="TY-THURAIYUR">Thuraiyur</option>
                        </>
                      )}

                      {district === "TI-TIRUNELVELI" && (
                        <>
                          <option value="AM-AMBASAMUDRAM">Ambasamudram</option>
                          <option value="NA-NANGUNERI">Nanguneri</option>
                          <option value="PA-PALAYAMKOTTAI">
                            Palayamkottai
                          </option>
                          <option value="SA-SANKARANKOVIL">
                            Sankarankovil
                          </option>
                          <option value="RA-RADHAPURAM">Radhapuram</option>
                          <option value="TI-TIRUNELVELI">Tirunelveli</option>
                          <option value="TN-THISAYANVILAI">
                            Tisaiyanvilai
                          </option>
                          <option value="CH-CHERANMAHADEVI">
                            Cheranmahadevi
                          </option>
                        </>
                      )}

                      {district === "TU-TIRUPATHUR" && (
                        <>
                          <option value="NA-NATRAMPALLI">Natrampalli</option>
                          <option value="TI-TIRUPATHUR">Tirupattur</option>
                          <option value="VA-VANIYAMBADI">Vaniyambadi</option>
                          <option value="AM-AMBUR">Ambur</option>
                        </>
                      )}
                      {district === "KK-KANNIYAKUMARI" && (
                        <>
                          <option value="AG-AGASTHEESWARAM">
                            Agastheeswaram
                          </option>
                          <option value="KA-KALKULAM">Kalkulam</option>
                          <option value="VI-VILAVANCODE">Vilavancode</option>
                          <option value="TH-THOVALAI">Thovalai</option>
                          <option value="TV-THIRUVATTAR">Thiruvattar</option>
                          <option value="KI-KILLIYUR">Killiyur</option>
                        </>
                      )}

                      {district === "TP-TIRUPPUR" && (
                        <>
                          <option value="AV-AVINASHI">Avinashi</option>
                          <option value="PA-PALLADAM">Palladam</option>
                          <option value="DH-DHARAPURAM">Dharapuram</option>
                          <option value="KA-KANGEYAM">Kangeyam</option>
                          <option value="MA-MADATHUKULAM">Madathukulam</option>
                          <option value="TI-TIRUPPUR">Tiruppur</option>
                          <option value="UD-UDUMALAIPETTAI">
                            Udumalaipettai
                          </option>
                          <option value="UK-UTHUKKULI">Uthukuli</option>
                        </>
                      )}

                      {district === "TL-TIRUVALLUR" && (
                        <>
                          <option value="AV-AVADI ">Avadi</option>
                          <option value="GU-GUMMIDIPOONDI">
                            Gummidipoondi
                          </option>
                          <option value="PA-PALLIPET">Pallipattu</option>
                          <option value="PO-PONNERI">Ponneri</option>
                          <option value="PM-POONAMALLEE">Poonamallee</option>
                          <option value="RK-RK PET">RK Pet</option>
                          <option value="TI-TIRUTHANI">Tiruttani</option>
                          <option value="TL-TIRUVALLUR">Tiruvallur</option>
                          <option value="UT-UTHUKKOTTAI">Uthukkottai</option>
                        </>
                      )}

                      {district === "TV-TIRUVANNAMALAI" && (
                        <>
                          <option value="AA-AARANI">Aarani</option>
                          <option value="SE-CHENGAM">Chengam</option>
                          <option value="CP-CHETTUPATTU">Chettupattu</option>
                          <option value="CH-CHEYYAR">Cheyyar</option>
                          <option value="JA-JAMUNAMARATHUR">
                            Jamunamarathur
                          </option>
                          <option value="KI-KILPENNATHUR">Kilpennathur</option>
                          <option value="KA-KALASAPAKKAM">Kalasapakkam</option>
                          <option value="PO-POLUR">Polur</option>
                          <option value="TH-THANDARAMPATTU">
                            Thandarampattu
                          </option>
                          <option value="TI-TIRUVANNAMALAI">
                            Tiruvannamalai
                          </option>
                          <option value="VA-VANTHAVASI">Vandavasi</option>
                          <option value="VE-VEMBAKKAM">Vembakkam</option>
                        </>
                      )}

                      {district === "TR-TIRUVARUR" && (
                        <>
                          <option value="KU-KUDAVASAL">Kudavasal</option>
                          <option value="KO-KOOTHANALLUR">Koothanallur</option>
                          <option value="MA-MANNARGUDI">Mannargudi</option>
                          <option value="NA-NANNILAM">Nannilam</option>
                          <option value="NE-NEEDAMANGALAM">
                            Needamangalam
                          </option>
                          <option value="TH-THIRUTHIRAIPOONDI">
                            Thiruthiraipoondi
                          </option>
                          <option value="TI-TIRUVARUR">Tiruvarur</option>
                          <option value="VA-VALANGAIMAN">Valangaiman</option>
                        </>
                      )}
                      {district === "VE-VELLORE" && (
                        <>
                          <option value="AN-ANAIKATTU">Anaicut</option>
                          <option value="GU-GUDIYATHAM">Gudiyatham</option>
                          <option value="PE-PERNAMBUT">Pernambut</option>
                          <option value="KA-KATPADI">Katpadi</option>
                          <option value="VE-VELLORE">Vellore</option>
                          <option value="PA-PALLIKONDA">Pallikonda</option>
                        </>
                      )}

                      {district === "VL-VILUPPURAM" && (
                        <>
                          <option value="GI-GINGEE">Gingee</option>
                          <option value="MA-MARAKKANAM">Marakkanam</option>
                          <option value="ME-MELMALAIANUR">Melmalaianur</option>
                          <option value="TI-TINDIVANAM">Tindivanam</option>
                          <option value="VA-VANUR">Vanur</option>
                          <option value="VK-VIKRAVANDI">Vikravandi</option>
                          <option value="VI-VILUPPURAM">Villupuram</option>
                        </>
                      )}

                      {district === "VR-VIRUDHUNAGAR" && (
                        <>
                          <option value="AR-ARUPPUKKOTTAI">
                            Aruppukkottai
                          </option>
                          <option value="KA-KARIAPATTI">Kariapatti</option>
                          <option value="KA-KARIAPATTI">Rajapalayam</option>
                          <option value="SA-SATTUR">Sattur</option>
                          <option value="SI-SIVAKASI">Sivakasi</option>
                          <option value="SR-SRIVILLIPUTHUR">
                            Srivilliputhur
                          </option>
                          <option value="TI-TIRUCHULI">Tiruchuli</option>
                          <option value="VI-VIRUDHUNAGAR">Virudhunagar</option>
                          <option value="WA-WATRAP">Watrap</option>
                          <option value="VE-VEMBAKOTTAI">Vembakottai</option>
                        </>
                      )}
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3 mb-3">
                    <label className="form-label">
                      GSTIN <span style={{ color: "red" }}>*</span>
                    </label>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="inlineRadioOptions"
                        id="inputZip"
                        value="gstNo"
                        onChange={handleRadioChange}
                        required
                      />
                      <label className="form-check-label">Yes</label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="inlineRadioOptions"
                        id="inputZip"
                        value="option2"
                        onChange={handleRadioChange}
                      />
                      <label className="form-check-label">No</label>
                    </div>
                    {showGSTINInput && (
                      <div>
                        <input
                          type="text"
                          className="form-control"
                          id="inputZip"
                          placeholder="Enter GSTIN"
                          maxLength={15}
                          value={gstNo}
                          onChange={(e) => {
                            setGstNo(e.target.value);
                            setgstValid(validategst(e.target.value));
                          }}
                          style={{ borderColor: gstvalid ? "initial" : "red" }}
                          required
                        />
                      </div>
                    )}
                  </div>
                  <div className="col-md-3 col-sm-3 mb-3">
                    <label for="inputZip" className="form-label">
                      Company Started Year{" "}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="inputPassword4"
                      name="dateOfBirth"
                      value={companyYear}
                      onChange={(e) => {
                        const inputYear = parseInt(e.target.value);
                        if (!isNaN(inputYear) && e.target.value.length === 4) {
                          // Check if the input is a valid number (year) and has exactly 4 digits
                          setCompanyyear(inputYear.toString()); // Store it as a string
                          setcompanyValid(true); // Set validation to true
                        } else {
                          // Handle invalid input
                          setCompanyyear(e.target.value);
                          setcompanyValid(false); // Set validation to false
                        }
                      }}
                      placeholder="YYYY"
                      required
                      maxLength={4} // Enforce a maximum length of 4 characters
                    />
                    {/* <input
                  type="text"
                  className="form-control"
                  id="inputZip"
                  value={companyYear}
                  onChange={(e) => {
                    setCompanyyear(e.target.value);
                    setcompanyValid(e.target.value !== "");
                  }}
                  style={{ borderColor: companyvalid ? "initial" : "red" }}
                  required
                /> */}
                  </div>
                  <div className="col-md-3 col-sm-3 mb-3">
                    <label for="inputZip" className="form-label">
                      Company Location <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="inputZip"
                      value={locationValue}
                      onChange={(e) => {
                        setLocationValue(e.target.value);
                        setlocationValid(e.target.value !== "");
                      }}
                      style={{ borderColor: locationvalid ? "initial" : "red" }}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-3 col-sm-3 mb-3 ">
                  <label for="inputPassword" className="form-label">
                    Reference Number <span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    type="tel"
                    inputMode="numeric"
                    className="form-control"
                    id="inputPassword"
                    value={referenceNumber}
                    onChange={(e) => {
                      setReferenceNumber(e.target.value);
                      setReferenceNumberValid(e.target.value.length === 10);
                    }}
                    maxLength={10}
                    style={{
                      borderColor: referenceNumberValid ? "initial" : "red",
                    }}
                    required
                  />
                </div>
                <div className="row">
                  <div className="col-md-3 col-sm-3 mb-3">
                    <label for="inputZip" className="form-label">
                      No of Permanent Employee{" "}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="tel"
                      className="form-control"
                      id="inputZip"
                      value={employee}
                      onChange={(e) => {
                        setEmployee(e.target.value);
                        setEmployeeValid(e.target.value !== "");
                      }}
                      style={{ borderColor: employeevalid ? "initial" : "red" }}
                      required
                    />
                  </div>
                  <div className="col-md-3 col-sm-3 mb-3">
                    <label for="inputZip" className="form-label">
                      No of Temporary Employee{" "}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="tel"
                      className="form-control"
                      id="inputZip"
                      value={employeeTemp}
                      onChange={(e) => {
                        setEmployeeTemp(e.target.value);
                        setEmployeeValidTemp(e.target.value !== "");
                      }}
                      style={{
                        borderColor: employeevalidTemp ? "initial" : "red",
                      }}
                      required
                    />
                  </div>

                  <div className="col-md-3 col-sm-3 mb-3">
                    <div
                      className="form-check form-check-inline"
                      style={{ marginTop: "11%" }}
                    >
                      <input
                        className="form-check-input"
                        type="radio"
                        name="inlineRadioOption"
                        id="inlineRadio1"
                        value="Ownshop"
                        checked={selectedOption === "Ownshop"}
                        onChange={handleRadioChange1}
                      />
                      <label className="form-check-label" for="inlineRadio1">
                        Own Shop
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="inlineRadioOption"
                        id="inlineRadio2"
                        value="Rentshop"
                        checked={selectedOption === "Rentshop"}
                        onChange={handleRadioChange1}
                      />
                      <label className="form-check-label" for="inlineRadio2">
                        Rental Shop
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center">
                {/* {loading && (
        <LoadingSpin
          duration="2s"
          width="15px"
          timingFunction="ease-in-out"
          direction="alternate"
          size="200px"
          primaryColor="yellow"
          secondaryColor="#333"
          numberOfRotationsInAnimation={2}
        />
      )} */}
                <button
                  type="submit"
                  className="sub-btn-member"
                  onClick={(e) => handleSubmit(e)}
                >
                  {loading ? "Loading" : "Submit"}
                </button>
              </div>
              <div>{/* {loading && <div>Loading...</div>} */}</div>
            </form>
          </div>
        </LoadingOverlay>
      </div>
    </div>
  );
};

export default Member;
