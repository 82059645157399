import React, { useEffect, useState } from "react";
import img from "../Images/logo.png";
import "./DashBoard.css";
import { Chart } from "react-google-charts";
import {
  FaDollarSign,
  FaGooglePay,
  FaIdCard,
  FaSdCard,
  FaSortAmountDown,
  FaUser,
} from "react-icons/fa";

const DashBoard = () => {
  const [productData, setProductData] = useState([]);
  const [report, setReport] = useState([]);
  const [cardtaken, setCardtaken] = useState([]);

  const fetchData = async () => {
    try {
      const req = await fetch("https://www.synologicsserver.in/user2");
      const res = await req.json();
      setProductData(res);

      // Get the total count of entries
      const totalCount = res.length;
      console.log("Total Count:", totalCount);
    } catch (error) {
      console.log(error);
    }
  };
  const paiddata = async () => {
    try {
      const req = await fetch("https://www.synologicsserver.in/newpay");
      const res = await req.json();
      setReport(res);

      // Get the total count of entries
      const totalCount = res.length;
      console.log("Total Count:", totalCount);
    } catch (error) {
      console.log(error);
    }
  };
  const card = async () => {
    try {
      const req = await fetch("https://www.synologicsserver.in/cardtaken");
      const res = await req.json();
      console.log(res);
      setCardtaken(res.CardTakenCount);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchData();
    paiddata();
    card();
  }, []);

  const data = [
    ["Task", "Hours per Day"],
    ["Entry", productData.length], // Add this line
    ["Paid members", report.length],
    ["Amount", report.length],
    ["Card", cardtaken.length],
    // ["Commute", 2],
    // ["Watch TV", 2],
    // ["Sleep", 7],
  ];
  const options = {
    // title: "My Daily Activities",
    is3D: true,
  };
  const [totalPaidAmount, setTotalPaidAmount] = useState(null);

  useEffect(() => {
    const fetchTotalPaidAmount = async () => {
      try {
        const req = await fetch("https://www.synologicsserver.in/paidamount");
        const res = await req.json();
        console.log("res", res.PaidAmount);
        setTotalPaidAmount(res.PaidAmount);
      } catch (error) {
        console.log(error);
      }
    };
    fetchTotalPaidAmount();
  }, []);

  return (
    <div className="dash-grid-container">
      <div className="dash-grid-item dgr-1 d-flex flex-row justify-content-between">
        <div>
          <FaUser className="fa-dash" />
        </div>
        <div className="d-flex flex-column justify-content-center">
          {cardtaken}
          <span className="dash-underfon">No of New Entry</span>
        </div>
      </div>
      <div className="dash-grid-item dgr-2 d-flex flex-row justify-content-between">
        <FaDollarSign className="fa-dash" />
        <div className="d-flex flex-column justify-content-center">
          {report.length}
          <span className="dash-underfon">No of Paid Members</span>
        </div>
      </div>
      <div className="dash-grid-item dgr-3 d-flex flex-row justify-content-between">
        <FaSortAmountDown className="fa-dash" />
        <div className="d-flex flex-column justify-content-center">
          {totalPaidAmount}
          <span className="dash-underfon">Total Paid Amount</span>
        </div>
      </div>
      <div className="dash-grid-item dgr-3 d-flex flex-row justify-content-between">
        <FaIdCard className="fa-dash" />
        <div className="d-flex flex-column justify-content-center">
          {cardtaken}
          <span className="dash-underfon">Total Entery</span>
        </div>
      </div>{" "}
      <Chart
        chartType="PieChart"
        data={data}
        options={options}
        style={{ width: "400px", height: "300px", margin: "0 auto" }}
      />
      <div />
    </div>
  );
};

export default DashBoard;
