import React, { useEffect, useState, useRef } from "react";
import { FaSearch } from "react-icons/fa";
import "./Payment.css";
import { useNavigate } from "react-router-dom";
import ReactToPrint from "react-to-print";
import Modal from "react-modal";
import Text from "react-text";
import img from "../Images/logo.png";
import img1 from "../Images/logo1.png";

const Payment = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [productdata, setProductdata] = useState([]);
  const [filter, setFilter] = useState([]);
  const [filter1, setFilter1] = useState([]);

  const [query, setQuery] = useState("");
  const [paidAmount, setPaidAmount] = useState("1000");
  const [selectedPaidMode, setSelectedPaidMode] = useState("Cash");
  const [totalAmount, setTotalAmount] = useState("1000");
  const [remarks, setRemarks] = useState("");
  const navigate = useNavigate();
  const [currentDate, setCurrentDate] = useState(new Date());

  const [phonenumber, setPhoneNumber] = useState("");
  const [PhoneNumberFilter, setPhoneNumberFilter] = useState([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState("");

  const [selectedMemberId, setSelectedmemberId] = useState("");
  console.log("kkkl", selectedMemberId);
  const [MemberDetails1, setMemberDetails1] = useState([]);

  const [MemberDetails2, setMemberDetails2] = useState([]);

  const modalRef = useRef(null);
  const componentRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const req = await fetch("https://www.synologicsserver.in/user2");
        const res = await req.json();
        setProductdata(res);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();

    const currentDate = new Date();
    const originalDate = currentDate.getDate(); // current date
    const originalMonth = currentDate.getMonth() + 1; // current month
    const originalYear = currentDate.getFullYear(); // current year
    const user_id = localStorage.getItem("admin_id");

    setCurrentDate(`${originalDate}-${originalMonth}-${originalYear}`);
  }, []);

  const [selectedItem, setSelectedItem] = useState(null);

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setQuery(inputValue);

    const filteredProducts = productdata.filter((item) => {
      const companyNameMatch = item.company_name
        .toLowerCase()
        .includes(inputValue.toLowerCase());
      return companyNameMatch;
    });
    setFilter(filteredProducts);

    const filteredProducts1 = filteredProducts.map(
      (item) => item.phone_number + "-" + item.company_name
    );

    setFilter1(filteredProducts1);

    const filteredIds = filteredProducts.map(
      (item) => item.member_id + " (" + item.company_name + ")"
    );
    setMemberDetails1(filteredIds);

    if (filteredProducts.length > 0) {
      setSelectedItem(filteredProducts[0]);

      setPhoneNumber(filteredProducts[0].phone_number);
      setSelectedmemberId(
        filteredProducts[0].member_id +
          " (" +
          filteredProducts[0].company_name +
          ")"
      );
    }
  };

  const handleSearchResultClick = (item) => {
    setSelectedItem(item);
    setQuery(item.company_name);

    setPhoneNumber(item.phone_number);
    setSelectedmemberId(item.member_id + " (" + item.company_name + ")");
  };

  const handleSelectChange = (e) => {
    const input = e.target.value;
    setPhoneNumber(input);

    const selectedProduct = productdata.find(
      (item) => item.phone_number === input
    );

    if (selectedProduct) {
      setQuery(selectedProduct.company_name);
      setSelectedmemberId(selectedProduct.member_id);
      setSelectedCompanyId(
        selectedProduct.member_id + " (" + selectedProduct.company_name + ")"
      );
    }

    const filteredPhoneNumbers = productdata.filter((item) => {
      const companyNumber = item.phone_number
        .toLowerCase()
        .includes(input.toLowerCase());
      return companyNumber;
    });

    const phoneNumbers = filteredPhoneNumbers.map((item) => item.phone_number);

    setPhoneNumberFilter(phoneNumbers);
  };

  // const handlememberidChange = (e) => {
  //   const input = e.target.value;
  //   setSelectedmemberId(input);
  //   const filteredPhoneNumbers = productdata.filter((item) => {
  //     const companyNumber = item.member_id
  //       .toLowerCase()
  //       .includes(input.toLowerCase());
  //     return companyNumber;
  //   });
  //   const phoneNumbers = filteredPhoneNumbers.map((item) => item.member_id);
  //   setPhoneNumberFilter(phoneNumbers);
  // };

  const handlememberidChange = (e) => {
    const input = e.target.value;
    setSelectedmemberId(input);

    const selectedProduct = productdata.find(
      (item) => item.member_id === input
    );

    if (selectedProduct) {
      setQuery(selectedProduct.company_name);
      setPhoneNumber(selectedProduct.phone_number);
      setSelectedCompanyId(
        selectedProduct.member_id + " (" + selectedProduct.company_name + ")"
      );
    }

    const filteredPhoneNumbers = productdata.filter((item) => {
      const companyNumber = item.member_id
        .toLowerCase()
        .includes(input.toLowerCase());
      return companyNumber;
    });

    const phoneNumbers = filteredPhoneNumbers.map((item) => item.member_id);

    setPhoneNumberFilter(phoneNumbers);
  };

  const inputString = phonenumber;
  const parts = inputString.split("-");
  console.log("stricng", parts[0]);
  const phonenoonly = parts[0];

  const partsmem = selectedMemberId.split("(");
  console.log("memId", partsmem[0]);
  const memId = partsmem[0];


  const handleInputChange1 = (e) => {
    const inputValue = e.target.value;
    setPhoneNumber(inputValue);
  };

  const handleUpdate = async () => {
    try {
      const response = await fetch(
        `https://www.synologicsserver.in/member_update?id=${memId}`,
        {
          method: "PUT",
          body: JSON.stringify({ paidAmount }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        if (data.success === 1) {
          console.log("Payment updated successfully!");
        } else {
          console.error("Payment update failed.");
        }
      } else {
        console.error("HTTP request failed.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleSubmit = async () => {
    if (!query || !paidAmount || !totalAmount) {
      console.error("Please fill in all required fields.");
      return;
    }
    const url = `https://www.synologicsserver.in/submit_payment`;
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query: memId,
          paidAmount: paidAmount,
          selectedPaidMode: selectedPaidMode,
          totalAmount: totalAmount,
          member_paid_date: currentDate,
          remarks: remarks,
          company_name: query,
          phonenumber: phonenoonly,
          member_print: 1,
          user_id: localStorage.getItem("admin_id"),
        }),
      });

      if (response.ok) {
        console.log("Payment submitted successfully!");
        window.location.reload()
        setIsModalOpen(true);
      } else {
        console.error("Payment submission failed.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handlePaidModeChange = (e) => {
    setSelectedPaidMode(e.target.value);
  };
  return (
    <div className="p-4 outer-div">
      <div className="out-div">
        <div className="container modelform">
          <h1 className="head-fon-mv p-3">Payment Form</h1>

          <div className="column">
            <div className="row">
              <div className="col-md-4">
                <div className="input-section">
                  <label className="form-label">Company Name :</label>
                  <div className="searchbar d-flex align-items-center">
                    <input
                      type="text"
                      className="search"
                      value={query}
                      onChange={handleInputChange}
                      list="companies"
                    />
                    <datalist id="companies">
                      {filter.map((member) => (
                        <React.Fragment key={member.id}>
                          <option value={member.company_name} />
                        </React.Fragment>
                      ))}
                    </datalist>
                    <FaSearch />
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="input-section">
                  <label className="form-label">Member PhoneNumber:</label>
                  <div className="searchbar d-flex align-items-center">
                    <input
                      type="text"
                      className="search"
                      value={phonenumber}
                      onChange={handleSelectChange}
                      list="phone_numbers"
                    />
                    <datalist id="phone_numbers">
                      {PhoneNumberFilter.map((item, index) => (
                        <React.Fragment key={index}>
                          <option value={item} />
                        </React.Fragment>
                      ))}
                    </datalist>
                    <select
                      className="form-control-payment"
                      value={phonenumber}
                      onChange={handleInputChange1}
                      style={{ display: "none" }}
                    >
                      {filter1.map((item, i) => (
                        <option key={i} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                    <FaSearch />
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="input-section">
                  <label className="form-label">Member id:</label>
                  <div className="searchbar d-flex align-items-center">
                    <input
                      type="tel"
                      className="search"
                      value={selectedMemberId}
                      onChange={handlememberidChange}
                      list="phone_numbers"
                    />
                    <datalist id="phone_numbers">
                      {PhoneNumberFilter.map((item, index) => (
                        <React.Fragment key={index}>
                          <option value={item} />
                        </React.Fragment>
                      ))}
                    </datalist>
                    <FaSearch />
                    {/* <select>
                      <option value=""></option>
                      {MemberDetails1.map(
                        (item, i) =>
                          item == "" && (
                            <option key={i} value={item}>
                              {item}
                            </option>
                          )
                      )}
                    </select> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="input-section">
              <label className="form-label">Paid Amount:</label>
              <div>
                <input
                  type="text"
                  className="form-control-payment"
                  value={paidAmount}
                  onChange={(e) => setPaidAmount(e.target.value)}
                />
              </div>
            </div>
            <div className="input-section">
              <label className="form-label">Paid Mode:</label>
              <div>
                <select
                  className="form-control-payment"
                  value={selectedPaidMode}
                  onChange={handlePaidModeChange}
                >
                  <option value="Cash">Cash</option>
                  <option value="Gpay">Gpay</option>
                </select>
              </div>
            </div>
            <div className="input-section">
              <label className="form- ">Total amount:</label>
              <div>
                <input
                  type="text"
                  className="form-control-payment"
                  value={totalAmount}
                  onChange={(e) => setTotalAmount(e.target.value)}
                />
              </div>
            </div>
            <div className="input-section">
              <label className="form-label">Remarks:</label>
              <div>
                <input
                  type="text"
                  className="form-control-payment"
                  value={remarks}
                  onChange={(e) => setRemarks(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="m-3 p-3">
            <button
              type="submit"
              className="btn btn-primary"
              style={{ marginLeft: "40%" }}
              onClick={() => {
                handleUpdate();
                handleSubmit();
              }}
            >
              Submit
            </button>
            {/* <button onClick={() => setIsModalOpen(true)}>Print</button> */}
          </div>
        </div>

        
      </div>
    </div>
  );
};

export default Payment;
