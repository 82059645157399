import React, { useState, useEffect, useRef, useCallback } from "react";
import { useLocation } from "react-router-dom";
import "./MemberDetails.css";
import Modal from "react-modal";
import Text from "react-text";
import ReactToPrint from "react-to-print";
import Webcam from "react-webcam";
// import { useHistory } from 'react-router-dom';
const MemberDetails = () => {
  const modalRef = useRef(null);
  const componentRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [fileValid2, setFileValid2] = useState(true);
  const location = useLocation();
  const selectedMemberHashcode = location.state?.data;
  console.log("member", selectedMemberHashcode);
  // const selecte = location.state?.data1;
  const [data, setData] = useState([]);
  const [qr, setQr] = useState();
  const [company, setCompany] = useState([]);
  console.log("compa", company);

  const [fileValid, setFileValid] = useState(true);
  const [fileValid1, setFileValid1] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showUpload, setShowUpload] = useState(false);

  const [filename1, setFilename1] = useState("");
  console.log("filename1", filename1.name);
  const [showFileInput1, setShowFileInput1] = useState(true);
  const [showFileInput2, setShowFileInput2] = useState(true);
  const [filenameimage, setFilenameimage] = useState("");

  const [img3, setImg3] = useState(null);
  useEffect(() => {
    getQRcode();
  }, [selectedMemberHashcode]);
  const getQRcode = async () => {
    try {
      const res = await fetch(
        `https://api.qrserver.com/v1/create-qr-code/?size=150x100&data=${selectedMemberHashcode}`
      );
      setQr(res.url);
    } catch (error) {
      console.log(error);
    }
  };

  const addressStyle = {
    lineHeight: "1", // Adjust this value to control the line height
    height: "50px",
  };

  const fetchData = () => {
    fetch("https://www.synologicsserver.in/user2")
      .then((res) => res.json())
      .then((data) => {
        setData(data);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleRefresh = () => {
    fetchData();
  };
  const handleFileInputChange = (event, inputNumber) => {
    const selectedFile = event.target.files[0];
    setFilename1(selectedFile);
    setShowFileInput1(false);
    if (selectedFile) {
      if (inputNumber === 1) {
        setFilename1(selectedFile);
        setFileValid1(selectedFile.size <= 350 * 1024);
        setShowFileInput2(false);
      } else if (inputNumber === 2) {
        setFileValid2(selectedFile.size <= 350 * 1024);
        setShowFileInput1(false);
      }
    }
  };
  useEffect(() => {
    fetch("https://www.synologicsserver.in/user1")
      .then((res) => res.json())
      .then((item) => {
        setCompany(item);
      })
      .catch((err) => console.log(err));
  }, []);

  const selectedMember = data.find(
    (member) => member.id === selectedMemberHashcode
  );
  console.log("selectedMember", selectedMember);
  const dob = selectedMember
    ? new Date(selectedMember.dob).toLocaleDateString()
    : "Not Found";
  const date = selectedMember
    ? new Date(selectedMember.member_due_date).toLocaleDateString()
    : "Not Found";
  const imageUrl = selectedMember ? selectedMember.member_image : null;
  console.log("image", imageUrl);
  const member_id = selectedMember ? selectedMember.member_id : null;
  console.log("member_id", member_id);

  const companyName = company.find(
    (item) => item.company_id === selectedMember?.company_id
  );

  const address = companyName ? companyName.company_address : null;
  console.log("address", address);

  const openModal = async () => {
    setIsModalOpen(true);

    // Check if selectedMember is defined
    // if (!selectedMember) {
    //   console.error('Selected member is undefined or null.');
    //   return;
    // }

    // try {
    //   const formData = new FormData();
    //   formData.append('file', img3);

    //   const response = await fetch(
    //     `https://www.synologicsserver.in/image?id=${selectedMember?.member_id}`,
    //     {
    //       method: 'PUT',
    //       body: formData,
    //     }
    //   );
    //   console.log(`https://www.synologicsserver.in/images?id=${selectedMember?.member_image}`)
    //   if (response.ok) {
    //     const data = await response.json();
    //     if (data.success === 1) {
    //       console.log('Image updated successfully!');
    //     } else {
    //       console.error('Image update failed.');
    //     }
    //   } else {
    //     console.error('HTTP request failed.');
    //   }
    // } catch (error) {
    //   console.error('Error:', error);
    // }
  };

  const photoSubmit = async () => {
    setClicked(true);
    try {
      const formData = new FormData();
      formData.append("file", img3);
      formData.append("file", filename1);

      const response = await fetch(
        `https://www.synologicsserver.in/image?id=${selectedMember.member_id}`,
        {
          method: "PUT",
          body: formData,
        }
      );

      if (response.ok) {
        const data = await response.json();
        if (data.success === 1) {
          console.log("Image updated successfully!");
        } else {
          console.error("Image update failed.");
        }
      } else {
        console.error("HTTP request failed.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const webcamRef = useRef(null);

  const videoConstraints = {
    width: 420,
    height: 420,
    facingMode: "user",
  };

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    const timestamp = Date.now();
    const filename = `Photo_${timestamp}.jpg`;
    const imageBlob = dataURItoBlob(imageSrc);
    const imageFile = new File([imageBlob], filename, { type: "image/jpeg" });
    setImg3(imageFile);
    setFilename1(imageFile);
    setFilenameimage(imageFile.name);
    setShowFileInput1(true);
    setShowFileInput2(false);
  }, [webcamRef]);

  const dataURItoBlob = (dataURI) => {
    if (dataURI === null) {
      return null;
    }
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  const opencamera = () => {
    setShowUpload(true);
  };
  const Goback = (event) => {
    event.preventDefault();
    window.history.back(); // This will navigate the user to the previous page in the browser history.
  };
  return (
    <div className="Card mt-5 mb-5">
      <div className="w-100 ">
        <div>
          <button onClick={Goback} className="sub-btn-member">
            Go Back
          </button>
        </div>
        <div className="registration-form4">
          <h2 class="head-fon-card">Member Details</h2>

          {selectedMember && companyName && (
            <>
              <div className="d-flex img-card-inner justify-content-between w-100 align-items-start">
                <div style={{ width: "25%" }} className="Columns">
                  <div className=" mb-3">
                    <img
                      src={`https://www.synologicsserver.in/images/` + imageUrl}
                      alt="Member Image"
                      className="uploaded-image p-3"
                    />
                  </div>
                  <button onClick={opencamera} className="sub-btn-member">
                    Take Photo
                  </button>
                  {showUpload && (
                    <div className="row d-flex flex-column m-0">
                      {showFileInput1 && (
                        <div className="pt-4">
                          <div>
                            <label htmlFor="file1" className="form-label">
                              Upload Image
                            </label>
                            {img3 ? (
                              <input
                                type="text"
                                className="form-control "
                                value={filenameimage}
                              />
                            ) : (
                              <input
                                type="text"
                                className="form-control "
                                value={filename1.name}
                              />
                            )}
                          </div>
                          <div className="Container pt-3 pb-3">
                            {img3 === null ? (
                              <>
                                <Webcam
                                  audio={false}
                                  mirrored={true}
                                  height={200}
                                  width={200}
                                  ref={webcamRef}
                                  screenshotFormat="image/jpeg"
                                  videoConstraints={videoConstraints}
                                />
                                <button
                                  className="btn btn-success "
                                  onClick={capture}
                                >
                                  Capture photo
                                </button>
                              </>
                            ) : (
                              <>
                                <div className="image-container">
                                  <img
                                    src={URL.createObjectURL(img3)}
                                    alt="screenshot"
                                  />
                                  <button
                                    className="btn-remove m-2"
                                    onClick={() => {
                                      setImg3(null);
                                      setShowFileInput2(true);
                                      setFilenameimage("");
                                    }}
                                  >
                                    Retake
                                  </button>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      )}
                      {showFileInput2 && (
                        <div className="">
                          {/* <label htmlFor="file2" className="form-control">
                            Upload File
                          </label> */}
                          <input
                            style={{ width: "80%" }}
                            type="file"
                            name="file2"
                            className={`mb-1 form-control ${
                              fileValid2 ? "" : "is-invalid"
                            }`}
                            id="myFile2"
                            accept=".jpg, .jpeg, .png,"
                            onChange={handleFileInputChange}
                          />
                          {!fileValid2 && (
                            <div className="invalid-feedback">
                              File size must be less than or equal to 250KB.
                            </div>
                          )}
                          {selectedFile && (
                            <div>
                              <img src={selectedFile} alt="Chosen file" />
                            </div>
                          )}
                          <button
                            className="btn-remove"
                            onClick={() => {
                              setSelectedFile(null);
                              setShowFileInput1(true);
                              // setShowFileInput2(true);
                            }}
                          >
                            Remove
                          </button>
                        </div>
                      )}
                      {clicked ? (
                        <button
                          onClick={() => {
                            handleRefresh();
                            window.location.reload();
                          }}
                          className="sub-btn-member mt-2"
                        >
                          Submit
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            photoSubmit();
                            handleRefresh();
                          }}
                          className="sub-btn-member mt-2"
                        >
                          Update image
                        </button>
                      )}
                    </div>
                  )}
                </div>

                <div style={{ width: "60%" }}>
                  <div className="col-lg-12">
                    <div className="row">
                      <div className="col-lg-2"></div>
                      <div className="col-lg-4">
                        <h3 className="input-fon-card m-0">Name</h3>
                      </div>
                      <div className="col-lg-6">
                        <p className="input-fon-card m-0">
                          : {selectedMember.member_name}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="row">
                      <div className="col-lg-2"></div>
                      <div className="col-lg-4">
                        <h3 className="input-fon-card m-0">Phone No</h3>
                      </div>
                      <div className="col-lg-6">
                        <p className="input-fon-card m-0">
                          : {selectedMember.phone_number}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="row">
                      <div className="col-lg-2"></div>
                      <div className="col-lg-4">
                        <h3 className="input-fon-card m-0">Alter No</h3>
                      </div>
                      <div className="col-lg-6">
                        <p className="input-fon-card m-0">
                          : {selectedMember.alter_number}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="row">
                      <div className="col-lg-2"></div>
                      <div className="col-lg-4">
                        <h3 className="input-fon-card m-0">Aadhar Number</h3>
                      </div>
                      <div className="col-lg-6">
                        <p className="input-fon-card m-0">
                          : {selectedMember.aadharnumber}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="row">
                      <div className="col-lg-2"></div>
                      <div className="col-lg-4">
                        <h3 className="input-fon-card m-0">Education</h3>
                      </div>
                      <div className="col-lg-6">
                        <p className="input-fon-card m-0">
                          : {selectedMember.education}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="row">
                      <div className="col-lg-2"></div>
                      <div className="col-lg-4">
                        <h3 className="input-fon-card m-0">Age</h3>
                      </div>
                      <div className="col-lg-6">
                        <p className="input-fon-card m-0">
                          : {selectedMember.age}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="row">
                      <div className="col-lg-2"></div>
                      <div className="col-lg-4">
                        <h3 className="input-fon-card m-0">Blood Group</h3>
                      </div>
                      <div className="col-lg-6">
                        <p className="input-fon-card m-0">
                          : {selectedMember.blood_group}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="row">
                      <div className="col-lg-2"></div>
                      <div className="col-lg-4">
                        <h3 className="input-fon-card m-0">DOB</h3>
                      </div>
                      <div className="col-lg-6">
                        <p className="input-fon-card m-0">: {dob}</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="row">
                      <div className="col-lg-2"></div>
                      <div className="col-lg-4">
                        <h3 className="input-fon-card m-0">Paid</h3>
                      </div>
                      <div className="col-lg-6">
                        <p className="input-fon-card m-0">
                          : {selectedMember.member_paid}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="row">
                      <div className="col-lg-2"></div>
                      <div className="col-lg-4">
                        <h3 className="input-fon-card m-0">Due Date</h3>
                      </div>
                      <div className="col-lg-6">
                        <p className="input-fon-card m-0">
                          : {selectedMember.member_due_date}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="row">
                      <div className="col-lg-2"></div>
                      <div className="col-lg-4">
                        <h3 className="input-fon-card m-0">Address</h3>
                      </div>
                      <div className="col-lg-6">
                        <p className="input-fon-card m-0">
                          : {companyName.company_address}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ width: "15%" }} className="Columns">
                  <div>
                    {selectedMember.member_paid != 1000 ? (
                      <h6>Please Pay 1000 Before QR Code Generation</h6>
                    ) : (
                      <img
                        src={qr}
                        alt="Member Image"
                        className="img-card-inner p-3"
                      />
                    )}
                  </div>
                  <button
                    onClick={() => openModal()}
                    className="sub-btn-member"
                  >
                    Print
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div>
        <Modal
          isOpen={isModalOpen}
          style={{
            overlay: {
              backgroundColor: "rgba(0, 0, 0, 0.5)",
            },
            content: {
              width: "40%",
              height: "70%",
              display: "flex",
              flexDirection: "column",
              padding: "0px",
            },
          }}
          onRequestClose={closeModal}
          contentLabel="Print Modal"
          ref={modalRef}
        >
          <div style={{ textAlign: "center" }}>
            <ReactToPrint
              trigger={() => <button className="sub-btn-print">Print</button>}
              content={() => componentRef.current}
              documentTitle="dummy"
              pageStyle="print"
              onAfterPrint={() => console.log("data printed")}
            />
            <div ref={componentRef}>
              <div style={{ textAlign: "center" }}>
                {selectedMember && (
                  <>
                    <div className="d-flex id-topout">
                      <div className="id-leftout">
                        <img
                          src={`https://www.synologicsserver.in/images/${selectedMember?.member_image}`}
                          alt="Member Image"
                          className="uploaded-image"
                        />
                        <div>
                          <div
                            className="fw-bold"
                            style={{ textAlign: "center" }}
                          >
                            <div className="id-memid">
                              <div>{selectedMember.member_id}</div>
                            </div>
                            <div className="d-flex id-memvalid justify-content-center">
                              <p className="m-0 p-0">VALID TILL:</p>
                              <div>{selectedMember.member_due_date}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="id-outmemname">
                        <div
                          className="id-inmemdet"
                          style={{ textAlign: "center" }}
                        >
                          <div>{selectedMember.member_name}</div>
                        </div>
                        <div
                          className="id-inmemdet"
                          style={{ textAlign: "center" }}
                        >
                          <div>{selectedMember.company_name}</div>
                        </div>
                        <div
                          className="id-inmemdet"
                          style={{ textAlign: "center" }}
                        >
                          <div>{selectedMember.phone_number}</div>
                        </div>
                        {selectedMember && selectedMember.id && (
                          <div className="id-qrout">
                            <div className="">
                              <div className="" style={{ textAlign: "center" }}>
                                {/* <div>{selectedMember.alter_number}</div> */}
                              </div>
                              <div className="" style={{ textAlign: "center" }}>
                                {/* <div>{selectedMember.company_address}</div> */}
                              </div>
                              <div className="" style={{ textAlign: "center" }}>
                                {/* <div>{selectedMember.dob}</div>
                                <div>{selectedMember.blood_group}</div> */}
                              </div>
                              <div className="" style={{ textAlign: "center" }}>
                                {/* <div>2023</div> */}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="idqrout">
                      <div className="id-qrin">
                        <div className="id-backcon" style={{ height: "28px" }}>
                          <div>{selectedMember.alter_number}</div>
                        </div>
                        {address && (
                          <div className="id-backcon" style={addressStyle}>
                            {address.split(",").map((line, index) => (
                              <div key={index}>{line.trim()}</div>
                            ))}
                          </div>
                        )}
                        <div
                          className="id-backcon"
                          style={{ height: "20px", marginRight: "0px" }}
                        >
                          {selectedMember.dob && (
                            <div>{selectedMember.dob}</div>
                          )}
                        </div>
                        <div
                          className="id-backcon"
                          style={{ height: "31px", marginRight: "-60px" }}
                        >
                          <div>{companyName?.cmpy_startyear}</div>
                        </div>
                      </div>
                      <div className="d-flex flex-column">
                        {selectedMember.member_paid != 1000 ? (
                          <h6>Please Pay 1000 Before QR Code Generation</h6>
                        ) : (
                          <img
                            src={qr}
                            alt="Member Image"
                            className="img-card-inner p-3"
                          />
                        )}
                        <div>
                          <p className="p-0 m-0 qr-underdate text-end">
                            <div>{selectedMember?.blood_group}</div>
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default MemberDetails;
